// src/i18nContext.js
import React, { createContext, useContext, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from '../locales/en/translation.json';
import translationZH from '../locales/zh/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  zh: {
    translation: translationZH
  }
};

// Initialize i18next without language detector
i18n
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['en', 'zh'],
    fallbackLng: 'en',
    debug: true
  });

// Context
export const I18nContext = createContext();

// Provider component
export const I18nProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <I18nContext.Provider value={{ t: i18n.t, changeLanguage }}>
      {children}
    </I18nContext.Provider>
  );
};
