import useWindowSize from '../../Hooks/useWindowSize';
import FaqSection from './Suppliers/FaqSection';
function FAQ() {
  const windowSize = useWindowSize();

  return (
    <div>
      <FaqSection windowSize={windowSize} />
    </div>
  );
}

export default FAQ;
