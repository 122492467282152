import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import Header from '../Header';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import AnimatedNumber from 'animated-number-react';
import config from '../../Config/config.json';

class ViewCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewcart: [],
      UserID: localStorage.getItem('user'),
      count: 0,
      Certificate: [],
      weight: 0,
      Discount: 0,
      Avg: 0,
      AvgDiscount: 0,
      selectedTotal: 0,
      selectedAvg: 0,
      selectedWeight: 0,
      selectedAvgWeight: 0,
      selectedDiscount: 0,
      selectedavgDiscount: 0,
      selectedAvg1: 0,
      InwardID: [],
      FullNameNotification: '',
      deviceId: '',
    };
    this.handlecheckbox = this.handlecheckbox.bind(this);
    this.PlaceOrder = this.PlaceOrder.bind(this);
    this.Deletecart = this.Deletecart.bind(this);
  }

  handlecheckbox(e, cart) {
    if (e.target.checked) {
      this.state.Certificate.push(cart.Certificate);
      this.state.InwardID.push(cart._id);
      this.state.selectedTotal += cart.NetValue;
      this.state.selectedAvg1 += cart.RapNetPrice;

      this.state.selectedAvg =
        this.state.selectedAvg1 / this.state.Certificate.length;

      this.state.selectedWeight += cart.Weight;
      this.state.selectedDiscount += cart.RapnetDiscount;

      this.state.selectedavgDiscount =
        this.state.selectedDiscount / this.state.Certificate.length;
    } else {
      var certificate = this.state.Certificate.indexOf(cart.Certificate);
      var inwardid = this.state.InwardID.indexOf(cart._id);
      this.state.Certificate.splice(certificate, 1);
      this.state.InwardID.splice(inwardid, 1);
      this.state.selectedTotal -= cart.NetValue;
      this.state.selectedAvg1 -= cart.RapNetPrice;

      this.state.selectedAvg =
        this.state.selectedAvg1 / this.state.Certificate.length;

      this.state.selectedWeight -= cart.Weight;
      this.state.selectedDiscount -= cart.RapnetDiscount;

      this.state.selectedavgDiscount =
        this.state.selectedDiscount / this.state.Certificate.length;
    }

    this.setState({
      Certificate: this.state.Certificate,
      InwardID: this.state.InwardID,
    });
  }
  Deletecart() {
    Axios.post(`/users/DeleteCart/${localStorage.getItem('ID')}`, {
      Certificates: this.state.Certificate,
    }).then((resp) => {
      if (resp.data.msg === 'success') {
        window.location.reload();
      }
    });
  }

  // Deletecart(e, id) {
  //     e.preventDefault()

  //     Axios.get(`/users/DeleteCart/${id}`).then((res) => {
  //         if (res.data.msg == 'Deleted') {
  //             swal({
  //                 title: "Done!",
  //                 text: "Delete Successfully",
  //                 icon: "success",
  //                 timer: 1000,
  //                 button: false
  //             })
  //         }
  //         this.componentDidMount()
  //     })

  // }

  viewData() {
    Axios.get(`/users/ViewCart/${this.state.UserID}`).then((res) => {
      this.setState({
        viewcart: res.data.inward,
        count: res.data.count,
        weight: res.data.weight,
        Discount: res.data.Discount,
        Avg: res.data.Avg,
        AvgDiscount: res.data.AvgDiscount,
      });
    });
  }

  componentDidMount() {
    Axios.get(`/users/ViewCart/${this.state.UserID}`).then((res) => {
      this.setState({
        viewcart: res.data.inward,
        count: res.data.count,
        weight: res.data.weight,
        Discount: res.data.Discount,
        Avg: res.data.Avg,
        AvgDiscount: res.data.AvgDiscount,
      });

      const script = document.createElement('script');
      script.src = `js/content.js`;
      script.async = true;
      document.body.appendChild(script);

      Axios.get('/getDeviceToken').then((resp) => {
        this.setState({
          deviceId: resp.data.deviceID,
        });
      });
    });
  }

  PlaceOrder(e) {
    Axios.get(`/getCustomerName/${localStorage.getItem('user')}`).then(
      (resp) => {
        this.setState({
          FullNameNotification: resp.data.fullname,
        });
      }
    );
    Axios.post('/users/placeorder', {
      Certificate: this.state.Certificate,
      InwardID: this.state.InwardID,
      UserID: this.state.UserID,
    }).then((res) => {
      swal('Order Place Successfully', '', 'success').then((value) => {
        window.location.reload();
      });
      const bodyData = {
        notification: {
          body: `An order has been placed by the customer ${this.state.FullNameNotification}`,
          title: 'New Order',
        },
        to: this.state.deviceId,
      };
      const options = {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: config.SECRET_KEY,
        },
        body: JSON.stringify(bodyData),
      };
      fetch('https://fcm.googleapis.com/fcm/send', options).then((response) => {
        return response.json();
      });
    });
  }

  formatValue = (value) => ` ${Number(value).toFixed(0)}`;
  formatValueWeight = (value) => `${Number(value).toFixed(2)}`;
  render() {
    const { viewcart } = this.state;
    return (
      <div className="top">
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content viewinward">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card" style={{ marginTop: '2%' }}>
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div style={{ display: 'inline-flex' }}>
                              <label
                                style={{ width: '174px', marginTop: '5px' }}
                              >
                                <b>Total Pcs : </b>
                                <AnimatedNumber
                                  value={parseInt(this.state.viewcart.length)}
                                  formatValue={this.formatValue}
                                />
                              </label>
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                              <label
                                style={{ width: '174px', marginTop: '5px' }}
                              >
                                <b>Total Price : </b>
                                <AnimatedNumber
                                  value={parseInt(this.state.count)}
                                  formatValue={this.formatValue}
                                />
                              </label>
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                              <label
                                style={{ width: '174px', marginTop: '5px' }}
                              >
                                <b>Total Avg Price : </b>
                                <AnimatedNumber
                                  value={this.state.Avg}
                                  formatValue={this.formatValueWeight}
                                />
                              </label>
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                              <label
                                style={{ width: '174px', marginTop: '5px' }}
                              >
                                <b>Total Weight : </b>
                                <AnimatedNumber
                                  value={this.state.weight}
                                  formatValue={this.formatValueWeight}
                                />
                              </label>
                            </div>

                            <div style={{ display: 'inline-flex' }}>
                              <label
                                style={{ width: '174px', marginTop: '5px' }}
                              >
                                <b>Avg Discount : </b>
                                <AnimatedNumber
                                  value={this.state.AvgDiscount}
                                  formatValue={this.formatValueWeight}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        {this.state.Certificate.length !== 0 ? (
                          <div className="row ">
                            <div className="col-md-12 text-center">
                              <div style={{ display: 'inline-flex' }}>
                                <label
                                  style={{ width: '174px', marginTop: '5px' }}
                                >
                                  <b>Selected Pcs : </b>
                                  <AnimatedNumber
                                    value={parseInt(
                                      this.state.Certificate.length
                                    )}
                                    formatValue={this.formatValue}
                                  />
                                </label>
                              </div>
                              <div style={{ display: 'inline-flex' }}>
                                <label
                                  style={{ width: '174px', marginTop: '5px' }}
                                >
                                  <b>Selected Amount : </b>
                                  <AnimatedNumber
                                    value={parseInt(this.state.selectedTotal)}
                                    formatValue={this.formatValue}
                                  />
                                </label>
                              </div>
                              <div style={{ display: 'inline-flex' }}>
                                <label
                                  style={{ width: '200px', marginTop: '5px' }}
                                >
                                  <b>Selected Avg Price : </b>
                                  <AnimatedNumber
                                    value={parseInt(this.state.selectedAvg)}
                                    formatValue={this.formatValue}
                                  />
                                </label>
                              </div>
                              <div style={{ display: 'inline-flex' }}>
                                <label
                                  style={{ width: '250px', marginTop: '5px' }}
                                >
                                  <b>Selected Total Weight : </b>
                                  <AnimatedNumber
                                    value={this.state.selectedWeight}
                                    formatValue={this.formatValueWeight}
                                  />
                                </label>
                              </div>
                              <div style={{ display: 'inline-flex' }}>
                                <label
                                  style={{ width: '250px', marginTop: '5px' }}
                                >
                                  <b>Selected Total Avg Discount : </b>
                                  <AnimatedNumber
                                    value={this.state.selectedavgDiscount}
                                    formatValue={this.formatValueWeight}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        <h3 className="card-title">View Cart Details</h3>
                        <button
                          className="btn btn-info"
                          style={{ float: 'right' }}
                          onClick={this.Deletecart}
                          disabled={this.state.Certificate.length == 0}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-info mr-2"
                          style={{ float: 'right' }}
                          onClick={this.PlaceOrder}
                        >
                          Place Order
                        </button>
                      </div>
                      <div className="card-body">
                        {this.state.viewcart ? (
                          <Fragment>
                            <div className="table-responsive zui-wrapper ">
                              <table
                                id="example1"
                                className="table table-bordered zui-table"
                              >
                                <thead>
                                  <tr className="text-center">
                                    <th className="">
                                      <input
                                        type="checkbox"
                                        style={{
                                          display: 'none',
                                          height: '35px',
                                        }}
                                      />
                                    </th>
                                    <th className="">Stock#</th>

                                    <th className="">Avl</th>
                                    <th className="">Shp</th>
                                    <th className="">Ct.</th>
                                    <th className="">Col.</th>
                                    <th className="">Cla.</th>

                                    <th>Cut.</th>
                                    <th>Pol.</th>
                                    <th>Sym.</th>
                                    <th>FL.</th>
                                    <th>Rap</th>
                                    <th>RapAmt</th>
                                    <th id="rapnetdiscount">Disc.</th>

                                    <th>$/ct</th>
                                    <th>Total$</th>
                                    <th>MM.</th>
                                    <th>Lab.</th>
                                    <th>Cert#</th>
                                    <th>Shade</th>
                                    <th>Milky</th>
                                    <th>SD Comment</th>
                                    <th>Treat.</th>
                                    <th>Dep.</th>
                                    <th>Tab.</th>
                                    <th>G.THN</th>
                                    <th>G.THK</th>
                                    <th>Girdle</th>
                                    <th>G.CON.</th>
                                    <th>C.Size</th>
                                    <th>C.Cond.</th>
                                    <th>CH</th>
                                    <th>CA</th>
                                    <th>PD</th>
                                    <th>PA</th>
                                    <th>KTS.</th>
                                    <th>LI</th>
                                    <th>C.Com.</th>
                                    <th className="">Location</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>S.Leng.</th>
                                    <th>Center Inc.</th>
                                    <th>Black Inc.</th>

                                    {/* <th>PDF Export</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.viewcart.map((view, index) => (
                                    <tr key={view._id} className="text-center">
                                      <td>
                                        <input
                                          type="checkbox"
                                          id={view.InwardDetail.Certificate}
                                          onChange={(e) => {
                                            this.handlecheckbox(
                                              e,
                                              view.InwardDetail
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="">
                                        {view.InwardDetail.Stock}
                                      </td>
                                      <td className="">
                                        {view.InwardDetail.Availability
                                          ? view.InwardDetail.Availability
                                          : '-'}
                                      </td>
                                      <td className="">
                                        {view.InwardDetail.Shape}
                                      </td>
                                      <td className="">
                                        {view.InwardDetail.Weight}
                                      </td>
                                      {view.InwardDetail.Color ? (
                                        <td className="">
                                          {view.InwardDetail.Color}
                                        </td>
                                      ) : (
                                        <td
                                          className="zui-sticky-col4"
                                          title={
                                            view.InwardDetail
                                              .FancyColorIntensity +
                                            ' ' +
                                            view.InwardDetail
                                              .FancyColorOvertone +
                                            ' ' +
                                            view.InwardDetail.FancyColor
                                          }
                                        >
                                          {view.InwardDetail
                                            .FancyColorIntensity +
                                            ' ' +
                                            view.InwardDetail
                                              .FancyColorOvertone +
                                            ' ' +
                                            view.InwardDetail.FancyColor}
                                        </td>
                                      )}
                                      <td className="">
                                        {view.InwardDetail.Clarity}
                                      </td>

                                      <td>{view.InwardDetail.CutGrade}</td>
                                      <td>{view.InwardDetail.Polish}</td>
                                      <td>{view.InwardDetail.Symmetry}</td>
                                      <td>
                                        {
                                          view.InwardDetail
                                            .FluorescenceIntensity
                                        }
                                      </td>
                                      <td>{view.InwardDetail.Rap}</td>
                                      <td>{view.InwardDetail.RapAmt}</td>
                                      <td id="rapnetdiscount">
                                        {view.InwardDetail.RapnetDiscount}
                                      </td>

                                      <td>{view.InwardDetail.RapNetPrice}</td>
                                      <td>{view.InwardDetail.NetValue}</td>

                                      <td>{view.InwardDetail.Measurements}</td>
                                      <td>{view.InwardDetail.Lab}</td>
                                      <td>{view.InwardDetail.Certificate}</td>
                                      <td>{view.InwardDetail.Shade}</td>
                                      <td>{view.InwardDetail.Milky}</td>
                                      <td>{view.InwardDetail.MemberComment}</td>
                                      <td>{view.InwardDetail.Treatment}</td>

                                      <td>{view.InwardDetail.Depth}</td>
                                      <td>{view.InwardDetail.Table}</td>
                                      <td>{view.InwardDetail.GirdleThin}</td>
                                      <td>{view.InwardDetail.GirdleThick}</td>
                                      <td>{view.InwardDetail.Girdle}</td>
                                      <td>
                                        {view.InwardDetail.GirdleCondition}
                                      </td>
                                      <td>{view.InwardDetail.CuletSize}</td>
                                      <td>
                                        {view.InwardDetail.CuletCondition}
                                      </td>
                                      <td>{view.InwardDetail.CrownHeight}</td>
                                      <td>{view.InwardDetail.CrownAngle}</td>
                                      <td>{view.InwardDetail.PavilionDepth}</td>
                                      <td>{view.InwardDetail.PavilionAngle}</td>
                                      <td>{view.InwardDetail.Keytosymbols}</td>

                                      <td>
                                        {view.InwardDetail.LaserInscription}
                                      </td>
                                      <td>{view.InwardDetail.Certcomment}</td>
                                      <td className="">
                                        {view.InwardDetail.Country}
                                      </td>
                                      <td>{view.InwardDetail.State}</td>
                                      <td>{view.InwardDetail.City}</td>

                                      <td>{view.InwardDetail.StarLength}</td>
                                      <td>
                                        {view.InwardDetail.CenterInclusion}
                                      </td>
                                      <td>
                                        {view.InwardDetail.BlackInclusion}
                                      </td>

                                      {/* <td><Link onClick={(e) => this.pdfDownload(e, view._id)}><i className="fa fa-download" style={{ color: "#00000080" }}></i></Link></td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Fragment>
                        ) : (
                          <div className="text-center">No Data Found</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewCart;
