import React, { useContext } from 'react';
import useWindowSize from '../../../Hooks/useWindowSize';
import HowItWorks from './HowItWorks';
import {
  SubWhyChooseUs_ContainerResponsive,
  SubWhyChooseUs_TextResponsive,
  SubWhyChooseUs_TitleResponsive,
  featDisplayResponsive,
  featDisplayResponsive2,
} from '../../../responsiveFunctions/LandingResponsive';
import LiveInventory from '../Landing/components/LiveInventory';
import SubWhyChooseUs from '../Landing/components/SubWhyChooseUs';
import Banner from './Banner';
import FaqSection from './FaqSection';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { I18nContext } from '../../../context/i18nContext';

const FeatureCards = ({ windowSize, title, tagLine, img }) => {
  const { t } = useContext(I18nContext);

  return (
    <>
      <div
        style={
          windowSize >= 800
            ? { marginTop: '20px', display: 'flex', flexDirection: 'column' }
            : {
                marginTop: '30px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }
        }
      >
        <div
          style={{
            // background: '#4B5563',
            height: '4rem',
            borderRadius: '0.375rem',
            width: '4rem',
            marginBottom: '1rem',
          }}
        >
          <img src={img} alt="logos" width={75} />
        </div>
        <div
          style={{
            fontSize: '1.125rem',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          {title}
        </div>
        <div
          style={
            windowSize >= 800
              ? { color: '#4B5563' }
              : {
                  textAlign: 'center',
                  color: '#4B5563',
                  width: '85%',
                }
          }
        >
          {tagLine}
        </div>
      </div>
    </>
  );
};

function WelcomeSection() {
  const { t } = useContext(I18nContext);
  const history = useHistory();
  return (
    <div
      style={{
        background: '#93C5FD',
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '1',
        alignItems: 'center',
      }}
    >
      <div style={{ color: '#374151', fontSize: '1.125rem' }}>
        {t('welcomeSupplier')}
      </div>

      <div
        style={{
          color: '#374151',
          fontWeight: 'bold',
          fontSize: '2.5rem',
          marginTop: '0.5rem',
          textAlign: 'center',
        }}
      >
        {t('welcomeSupplierS1')}
      </div>
      <div style={{ marginTop: '20px' }}>
        <button
          style={{
            background: 'rgb(31, 41, 55)',
            borderRadius: '0.375rem',
            color: 'white',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            paddingTop: '0.45rem',
            paddingBottom: '0.45rem',
          }}
          onClick={() => {
            window.open('https://admin.shreediamonds.in/Supplier', '_blank');
          }}
        >
          {t('joinNow')}
        </button>
      </div>
    </div>
  );
}

function ForSuppliers({ currentPath, setCurrentPath }) {
  const { t } = useContext(I18nContext);
  const image1 = '/Logos/Increased-Visibility.png';
  const image2 = '/Logos/Expanded-Market-Reach.png';
  const image3 = '/Logos/Streamlined-Sales-Process.png';
  const image4 = '/Logos/Trust-and-Credibility.png';
  const image5 = '/Logos/Secure-Transactions.png';
  const image6 = '/Logos/Insights-and-Analytics.png';
  const image7 = '/Illustrations/diamondInventorySystem.png';
  const windowSize = useWindowSize();

  return (
    <>
      <WelcomeSection />
      <div style={{ ...featDisplayResponsive2(windowSize) }}>
        <FeatureCards
          title={`${t('welcomeCh1')}`}
          tagLine={`${t('welcomeCd1')}`}
          img={image1}
        />
        <FeatureCards
          title={`${t('welcomeCh2')}`}
          tagLine={`${t('welcomeCd3')}`}
          img={image2}
        />
        <FeatureCards
          title={`${t('welcomeCh3')}`}
          tagLine={`${t('welcomeCd3')}`}
          img={image3}
        />
      </div>
      <div style={{ ...featDisplayResponsive2(windowSize) }}>
        <FeatureCards
          title={`${t('welcomeCh4')}`}
          tagLine={`${t('welcomeCd4')}`}
          img={image4}
        />
        <FeatureCards
          title={`${t('welcomeCh5')}`}
          tagLine={`${t('welcomeCd5')}`}
          img={image5}
        />
        <FeatureCards
          title={`${t('welcomeCh6')}`}
          tagLine={`${t('welcomeCd6')}`}
          img={image6}
        />
      </div>
      <div
        style={{
          width: '100%',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          alignContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '3rem',
            paddingBottom: '2.5rem',
            marginLeft: windowSize <= 800 ? '1rem' : '5rem',
            marginRight: windowSize <= 800 ? '1rem' : '5rem',
            width: '80%',
            margin: 'auto',
            ...SubWhyChooseUs_ContainerResponsive(windowSize),
          }}
        >
          <div
            style={
              windowSize >= 800
                ? {
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
          >
            <div
              style={{
                fontWeight: '600',
                fontSize: '1.5rem',
                marginBottom: '0.25rem',
                width: '100%',
                textAlign: windowSize > 800 ? 'center' : 'center',
                ...SubWhyChooseUs_TitleResponsive(windowSize),
              }}
            >
              {t('whyChoose')}
            </div>

            <div style={{ ...SubWhyChooseUs_TextResponsive(windowSize) }}>
            {t('whyChooseDesc')}
            </div>
          </div>

          <div
            style={
              windowSize >= 800
                ? {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                  }
                : {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }
            }
          >
            <div
              style={{
                height: '16rem',
                width: '16rem',
                // background: '#9CA3AF',
                margin: windowSize <= 800 ? 'auto' : '0rem',
              }}
            >
              <img src={image7} alt="why-choose" width={400} />
            </div>
          </div>
        </div>
      </div>
      <HowItWorks title={'Step1'} windowSize={windowSize} />
      <Banner windowSize={windowSize} />
      <FaqSection windowSize={windowSize} />
    </>
  );
}

export default ForSuppliers;
