import React from 'react';
import {
  SubWhyChooseUs_ContainerResponsive,
  SubWhyChooseUs_TextResponsive,
  SubWhyChooseUs_TitleResponsive,
} from '../../../../responsiveFunctions/LandingResponsive';

const SubWhyChooseUs = ({
  windowSize,
  img,
  title,
  text,
  directionRev,
  textHeader,
  noHr,
}) => {
  if (windowSize <= 800) {
    directionRev = false;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '3rem',
        paddingBottom: '2.5rem',
        marginLeft: windowSize <= 800 ? '1rem' : '5rem',
        marginRight: windowSize <= 800 ? '1rem' : '5rem',
        borderBottom: `${noHr ? '0' : '1'}px solid #E5E7EB`,
        width: '80%',
        ...SubWhyChooseUs_ContainerResponsive(windowSize),
      }}
    >
      {directionRev && (
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              height: '16rem',
              width: '16rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={img} alt="diamond" width="350rem" />
          </div>
        </div>
      )}
      <div
        style={
          windowSize >= 800
            ? {
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }
        }
      >
        <div
          style={{
            fontWeight: '600',
            fontSize: '1.5rem',
            marginBottom: '0.25rem',
            width: '100%',
            textAlign: windowSize > 800 ? 'center' : 'center',
            ...SubWhyChooseUs_TitleResponsive(windowSize),
          }}
        >
          {title}
        </div>
        {textHeader && (
          <div
            style={{
              ...SubWhyChooseUs_TextResponsive(windowSize),
              fontWeight: '600',
            }}
          >
            {textHeader}
          </div>
        )}
        <div style={{ ...SubWhyChooseUs_TextResponsive(windowSize) }}>
          {text}
        </div>
      </div>

      {!directionRev && (
        <div
          style={
            windowSize >= 800
              ? {
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }
          }
        >
          <div
            style={{
              height: '16rem',
              width: '16rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: windowSize <= 800 ? 'auto' : '0rem',
            }}
          >
            <img src={img} alt="diamond" width="350rem" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubWhyChooseUs;
