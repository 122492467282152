import { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { I18nContext } from '../../../context/i18nContext';

const Banner = ({ windowSize }) => {
  const image = '/Illustrations/mebp.png';
  const history = useHistory();
  const { t } = useContext(I18nContext);

  return (
    <div
      style={{
        width: '100%',
        display: windowSize < 800 ? 'block' : 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        height: '80vh',
        borderBottom: '1px solid #E5E7EB',
        borderTop: '1px solid #E5E7EB',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: windowSize < 800 ? '65%' : '65%',
          margin: 'auto',
        }}
      >
        {/* <div style={{ color: '#3B82F6' }}>Sub Title</div> */}

        <div
          style={{
            marginTop: '0.5rem',
            fontSize: '2rem',
            fontWeight: '600',
            textAlign: windowSize < 800 ? 'center' : 'left',
          }}
        >
          {t('incSale')}
        </div>

        <div
          style={{
            marginTop: '0.75rem',
            color: '#4B5563',
            textAlign: windowSize < 800 ? 'center' : 'left',
          }}
        >
          {t('incSaleDesc')}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // background: '#4B5563',
          marginTop: windowSize < 800 ? '2rem' : 0,
          paddingTop: windowSize < 800 ? '2rem' : 0,
        }}
      >
        <img src={image} width={450} alt="logo" />
        <div>
          <h1 style={{ textAlign: 'center', fontWeight: 600, color: 'black' }}>
            {t('welcomeSup')}
          </h1>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              style={{
                padding: '0.75rem 2.5rem',
                marginTop: '0.25rem',
                marginBottom: windowSize < 800 ? '2rem' : 0,
                background: '#1F2937',
                borderRadius: '0.375rem',
                fontSize: '1.125rem',
                color: '#FFFFFF',
              }}
              onClick={() => {
                window.open(
                  'https://admin.shreediamonds.in/Supplier',
                  '_blank'
                );
              }}
            >
              {t('joinNow')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
