import { useContext } from 'react';
import {
  FeaturesAndBenefits_ContainerResponsive,
  FeaturesAndBenefits_PlaceHolderResponsive,
} from '../../../../responsiveFunctions/LandingResponsive';
import { I18nContext } from '../../../../context/i18nContext';

const FeaturesAndBenfits = ({ windowSize }) => {
  const image = '/Illustrations/QUALITY-CHECK.png';
  const { t } = useContext(I18nContext);

  return (
    <div
      style={{
        marginRight: windowSize <= 800 ? '3.5rem' : '7rem',
        marginLeft: windowSize <= 800 ? '3.5rem' : '7rem',
        paddingBottom: '2rem',
        marginTop: '2.25rem',
        borderBottom: `${windowSize <= 800 ? '1' : '0'}px solid #E5E7EB`,
        marginBottom: '2.25rem',
        alignItems: 'center',
      }}
    >
      <h4
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '1.875rem',
        }}
      >
        {t('featureNBenefits')}
      </h4>
      <div
        style={{
          marginTop: '3.5rem',
          display: 'flex',
          gap: '5rem',
          alignItems: 'center',
          ...FeaturesAndBenefits_ContainerResponsive(windowSize),
        }}
      >
        <div>
          <h3>{t('features')}</h3>
          <ul style={{ paddingLeft: '0rem' }}>
            <li>{t('feat1')}</li>
            <li>{t('feat2')}</li>
            <li>{t('feat3')}</li>
            <li>{t('feat4')}</li>
            <li>{t('feat5')}</li>
          </ul>
        </div>

        <div>
          <h3>{t('benefits')}</h3>
          <ul style={{ paddingLeft: '0rem' }}>
            <li>{t('benefit1')}</li>
            <li>{t('benefit2')}</li>
            <li>{t('benefit3')}</li>
            <li>{t('benefit4')}</li>
            <li>{t('benefit5')}</li>
          </ul>
        </div>
        <div
          style={{
            width: '70%',
            alignContent: 'center',
            display: 'flex',
            // background: 'rgb(156, 163, 175)',
            ...FeaturesAndBenefits_PlaceHolderResponsive(windowSize),
          }}
        >
          <img src={image} width="100%" alt="featuresBenefits" />
        </div>
      </div>
    </div>
  );
};

export default FeaturesAndBenfits;
