import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import Header from '../Header';

class Compare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.location.data,
            arr: []
        }
    }
    componentDidMount() {


        Axios.get(`/users/CompareStones/${this.state.data}`).then((res) => {
            this.setState({
                arr: res.data.inward
            })
        })
    }

    render() {
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">

                        <section className="content">
                            <div className="container">
                                <div className="card pt-2">
                                    <div className="card-header">
                                        <h3 className="card-title">Comapre Stones</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row pt-2">
                                            <div className="col-md-12">
                                                <div className="table-responsive">

                                                    <div className="table-compare">
                                                        <table className="table table-striped table-bordered">

                                                            <tbody>


                                                                <tr>
                                                                    <th>Stock</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Stock}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Availability</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Availability}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Shape</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Shape}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Weight</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Weight}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Color</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Color}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Clarity</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Clarity}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CutGrade</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CutGrade}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Polish</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Polish}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Symmetry</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Symmetry}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>FluorescenceIntensity</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.FluorescenceIntensity}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>FluorescenceColor</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.FluorescenceColor}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Measurements</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Measurements}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Lab</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Lab}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Certificate</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Certificate}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Treatment</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Treatment}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Rap</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Rap}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>RapAmt</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.RapAmt}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>RapnetDiscount</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.RapnetDiscount}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>RapNetPrice</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.RapNetPrice}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>NetValue</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.NetValue}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CashPrice</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CashPrice}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CashPriceDiscount</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CashPriceDiscount}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>FancyColor</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.FancyColor}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>FancyColorIntensity</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.FancyColorIntensity}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>FancyColorOvertone</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.FancyColorOvertone}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Depth</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Depth}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Table</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Table}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>GirdleThin</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.GirdleThin}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>GirdleThick</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.GirdleThick}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Girdle</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Girdle}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>GirdleCondition</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.GirdleCondition}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CuletSize</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CuletSize}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CuletCondition</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CuletCondition}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CrownHeight</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CrownHeight}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CrownAngle</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CrownAngle}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>PavilionDepth</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.PavilionDepth}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>PavilionAngle</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.PavilionAngle}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>LaserInscription</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.LaserInscription}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Certcomment</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Certcomment}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Country</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Country}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>State</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.State}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>City</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.City}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Timetolocation</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Timetolocation}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>IsMatchedPairSeparable</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.IsMatchedPairSeparable}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>PairStock</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.PairStock}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>AllowRapLinkFeed</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.AllowRapLinkFeed}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>ParcelStones</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.ParcelStones}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CertificateFilename</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CertificateFilename}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>DiamondImage</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.DiamondImage}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>SarinLoupe</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.SarinLoupe}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>TradeShow</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.TradeShow}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Keytosymbols</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Keytosymbols}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Shade</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Shade}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>StarLength</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.StarLength}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>CenterInclusion</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.CenterInclusion}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>BlackInclusion</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.BlackInclusion}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>MemberComment</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.MemberComment}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>ReportIssueDate</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.ReportIssueDate}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>ReportType</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.ReportType}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>LabLocation</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.LabLocation}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Brand</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Brand}</td>
                                                                    ))}
                                                                </tr>
                                                                <tr>
                                                                    <th>Milky</th>
                                                                    {this.state.arr.map((view) => (
                                                                        <td>{view.Milky}</td>
                                                                    ))}
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>







            </div>
        );
    }
}

export default Compare;