import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import Login from './Authentication/Login';
import Home from './Components/Home';
import RequestAPI from './Components/API/RequestAPI';
import PrivateRoute from './Authentication/PrivateRoute';
import ViewInward from './Components/Inwards/ViewInward';
import ChangePassword from './Authentication/ChangePassword';
import AddCustomer from './Authentication/AddCustomer';
import ForgotPassword from './Authentication/ForgotPassword';
import ResetPassword from './Authentication/ResetPassword';
import CheckOTP from './Authentication/CheckOTP';

import ViewWishlist from './Components/Wishlist/ViewWishlist';
import ViewCart from './Components/Cart/ViewCart';
import Compare from './Components/Compare/Compare';
import SearchDiamond from './Components/Inwards/SearchDiamond';
import DisplayImage from './Components/Inwards/DisplayImage';
import Stoneoffer from './Components/Inwards/Stoneoffer';
import ViewOrder from './Components/Orders/ViewOrder';
import RejectedOrders from './Components/Orders/RejectedOrders';
import ConfirmedOrders from './Components/Orders/ConfirmedOrders';
import DisplayVideo from './Components/Inwards/DisplayVideo';
import Landing from './Components/Pages/Landing/Landing';
import OtpVerified from './Components/OtpVerified';
import Dashboard from './Components/Dashboard';
import ChooseDiamondType from './Components/ChooseDiamondType';


function App() {
  return (
    <Router>
      <Fragment>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/aboutUs" component={Landing} />
          <Route exact path="/whyus" component={Landing} />
          <Route exact path="/forSuppliers" component={Landing} />
          <Route exact path="/contactUs" component={Landing} />
          <Route exact path="/tnc" component={Landing} />
          <Route exact path="/privacyPolicy" component={Landing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/verifyOtp" component={OtpVerified} />
          <Route exact path="/AddCustomer" component={AddCustomer} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/checkOTP" component={CheckOTP} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/Video" component={DisplayImage} />
          <PrivateRoute exact path="/Video1" component={DisplayVideo} />
          <PrivateRoute exact path="/custHome" component={Home} />
          <PrivateRoute exact path="/requestAPI" component={RequestAPI} />
          <PrivateRoute
            exact
            path="/viewInwardCustomer"
            component={ViewInward}
          />
          <PrivateRoute
            exact
            path="/changePassword"
            component={ChangePassword}
          />
          <Route exact path="/diamondList" component={Dashboard} />
          <Route exact path="/chooseDiamondType" component={ChooseDiamondType} />
          <PrivateRoute exact path="/viewWishlist" component={ViewWishlist} />
          <PrivateRoute exact path="/viewCart" component={ViewCart} />
          <PrivateRoute exact path="/ViewCompareStones" component={Compare} />
          <PrivateRoute exact path="/searchDiamond" component={SearchDiamond} />
          <PrivateRoute exact path="/Demand" component={Stoneoffer} />
          <PrivateRoute exact path="/ViewOrder" component={ViewOrder} />
          <PrivateRoute
            exact
            path="/rejectedOrders"
            component={RejectedOrders}
          />
          <PrivateRoute
            exact
            path="/acceptedOrders"
            component={ConfirmedOrders}
          />
        </Switch>
      </Fragment>
    </Router>
  );
}

export default App;
