import React, { useContext } from 'react';
import './ContactUs.css';
import PageHeader from '../PageComponents/PageHeader';
import useWindowSize from '../../../Hooks/useWindowSize';
import { RxMobile } from 'react-icons/rx';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { AiFillSkype, AiOutlineMail } from 'react-icons/ai';
import { I18nContext } from '../../../context/i18nContext';

function ContactUs() {
  const { t } = useContext(I18nContext);
  const size = useWindowSize();

  return (
    <>
      <div
        style={{
          width: '100%',
          paddingBottom: '2rem',
        }}
      >
        <PageHeader title={`${t('contactUs')}`} />
      </div>
      <div
        style={{
          width: '80%',
          margin: 'auto',
          display: 'flex',
          flexDirection: size > 800 ? 'row' : 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: size > 800 ? '20%' : '80%',
            height: '15rem',
            margin: '5%',
            marginTop: '0rem',
            background: '#E1E2E3',
            borderRadius: '0.5rem',
            textAlign: 'center',
            padding: '1rem',
          }}
        >
          <RxMobile size="4rem" />
          <div
            style={{
              paddingTop: '3rem',
              fontSize: '1.5rem',
              marginTop: 'auto',
              justifyContent: 'center',
            }}
          >
            +91 9898773044
          </div>
        </div>
        <div
          style={{
            width: size > 800 ? '20%' : '80%',
            height: '15rem',
            margin: '5%',
            marginTop: '0rem',
            background: '#E1E2E3',
            borderRadius: '0.5rem',
            textAlign: 'center',
            padding: '1rem',
          }}
        >
          <HiOutlineOfficeBuilding size="4rem" />
          <div
            style={{
              paddingTop: '3rem',
              fontSize: '1.5rem',
            }}
          >
            Surat, Gujarat, India
          </div>
        </div>

        <div
          style={{
            width: size > 800 ? '20%' : '80%',
            height: '15rem',
            margin: '5%',
            marginTop: '0rem',
            background: '#E1E2E3',
            borderRadius: '0.5rem',
            textAlign: 'center',
            padding: '1rem',
          }}
        >
          <AiOutlineMail size="4rem" />
          <div
            style={{
              paddingTop: '2rem',
              fontSize: '1.5rem',
              wordBreak: 'break-all',
            }}
          >
            info@shreediamonds.com
          </div>
        </div>
      </div>
      <div
        style={{
          width: '80%',
          margin: 'auto',
          display: 'flex',
          flexDirection: size > 800 ? 'row' : 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: size > 800 ? '20%' : '80%',
            height: '15rem',
            margin: '5%',
            marginTop: '0rem',
            background: '#E1E2E3',
            borderRadius: '0.5rem',
            textAlign: 'center',
            padding: '1rem',
          }}
        >
          <AiFillSkype size="4rem" />
          <div
            style={{
              paddingTop: '3rem',
              fontSize: '1.5rem',
              wordBreak: 'break-all',
            }}
          >
            shreediams
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
