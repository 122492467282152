import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// i18next internationlization
import './i18n.js';
import { I18nProvider } from './context/i18nContext.js';
import { DiamondProvider } from './context/DiamondContext.js';

// Axios.defaults.baseURL = 'https://www.shreediamonds.in/cus-api';
Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <React.StrictMode>
    <I18nProvider>
      <DiamondProvider>
        <App />
      </DiamondProvider>
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
