export const responsiveHeader = (windowSize) => {
  return windowSize <= 800
    ? {
        display: 'none',
      }
    : {};
};

export const responsiveNavigation = (windowSize) => {
  return windowSize <= 800
    ? {
        paddingTop: '2rem',
        paddingBottom: '1rem',
      }
    : {
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
      };
};
