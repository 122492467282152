import React, { createContext, useState } from 'react';

const DiamondContext = createContext();

const DiamondProvider = ({ children }) => {
  const [selectedDiamond, setSelectedDiamond] = useState(null);
  const [diamondData, setDiamondData] = useState({
    record: [],
    filtered: {},
    totalWeight: 0,
    totalPrice: 0,
    avg1: 0,
    RapDisc: 0,
    count: 0,
  });

  const handleSelectDiamond = (diamondType) => {
    setSelectedDiamond(diamondType);
  };

  return (
    <DiamondContext.Provider value={{ selectedDiamond, handleSelectDiamond, diamondData, setDiamondData }}>
      {children}
    </DiamondContext.Provider>
  );
};

export { DiamondContext, DiamondProvider };
