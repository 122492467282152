import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Footer() {
  const history = useHistory();
  return (
    <div
      style={{
        width: '100%',
        marginLeft: 'auto',
      }}
    ></div>
  );
}

export default Footer;
