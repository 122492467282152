import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import Axios from 'axios';
import swal from 'sweetalert';
import { token } from 'morgan';
import config from '../Config/config.json';
import { I18nContext } from "../context/i18nContext.js";

class Header extends Component {
  static contextType = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      UserName: localStorage.getItem('user'),
      redirect: '',
      token: localStorage.getItem('token'),
      deviceId: '',
      FullName: '',
      navActive: true,
    };
    this.logout = this.logout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }
  logout(e) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('tokendevice');
    this.setState({
      redirect: '/',
    });
  }
  handleSubmit(event) {
    event.preventDefault();

    Axios.post('/apiRequest', this.state, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    }).then((res) => {
      this.setState({
        accepted: res.data.msg,
      });
      if (res.data.msg === 'ACCEPTED') {
        swal('Your request has already been sent', '', 'warning');
      } else {
        swal('REQUEST SENT', '', 'success');
        const bodyData = {
          notification: {
            body: `${this.state.FullName} has requested for accessing the API`,
            title: 'API access request',
          },
          to: this.state.deviceId,
        };
        const options = {
          method: 'post',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: config.SECRET_KEY,
          },
          body: JSON.stringify(bodyData),
        };
        fetch('https://fcm.googleapis.com/fcm/send', options).then(
          (response) => {
            //console.log(response)

            return response.json();
          }
        );
      }
    });
  }

  componentDidMount() {
    // console.log("USERNAME IN HEADER "+localStorage.getItem('user'))
    if (this.state.token == '' || this.state.UserName == '') {
      this.props.history.push('/');
    }
    Axios.get(`/getCustomerName/${localStorage.getItem('user')}`).then(
      (resp) => {
        this.setState({
          FullName: resp.data.fullname,
        });
      }
    );
    Axios.get('/getDeviceToken').then((resp) => {
      this.setState({
        deviceId: resp.data.deviceID,
      });
    });
  }

  handleChangeLanguage(event) {
    const { changeLanguage } = this.context;
    changeLanguage(event.target.value);
  }

  render() {
    const { t } = this.context;
    const { UserName } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="wrapper">
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    navActive: !this.state.navActive,
                  });
                }}
              >
                <i class="fas fa-bars"></i>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              Select Language:
              <select onChange={this.handleChangeLanguage}>
                <option value="en">English</option>
                <option value="zh">中文</option>
              </select>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt"></i>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                id="dropdownSubMenu1"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle "
              >
                <b
                  style={{ fontFamily: '-webkit-pictograph', fontSize: '15px' }}
                >
                  {UserName}
                </b>
                &nbsp;
                <img src="../../dist/img/UserImage.png" width="30px" />
              </a>
              <Link onClick={(e) => this.logout(e)}>
                <div className="dropdown-menu dropdown-menu-right text-center">
                  <span style={{ color: 'black' }}>
                    <i class="fas fa-power-off"></i>&nbsp;<b>Logout</b>
                  </span>
                </div>
              </Link>
            </li>
          </ul>
        </nav>

        {/* Sidebar component start here */}
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link className="brand-link">
            <img
              src="../../dist/img/favicon.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle "
            />
            <span className="brand-text font-weight-light">
              SHREE DIAMONDS{' '}
            </span>
            {/* <span><a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fa fa-arrow-left"></i></a></span> */}
          </Link>
          <div className="sidebar">
            <div className="form-inline"></div>
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pushbutton"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i
                      class="fas fa-hand-point-left"
                      style={{ fontSize: '25px' }}
                    ></i>
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/custHome" className="nav-link">
                    <i className="nav-icon fas fa-columns"></i>
                    <p>{t('home')}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/searchDiamond" className="nav-link">
                    <i className="far fa-gem nav-icon"></i>
                    <p>{t('diamonds')}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/ViewCart" className="nav-link">
                    <i className="fas fa-shopping-cart nav-icon"></i>
                    <p>{t('cartList')}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/ViewWishlist" className="nav-link">
                    <i className="fa fa-heart nav-icon"></i>
                    <p>{t('wishList')}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/ViewOrder" className="nav-link">
                    <i className="fa fa-shopping-cart nav-icon"></i>
                    <p>{t('yourOrders')}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={this.handleSubmit}>
                    <i className="fas fa-users nav-icon"></i>
                    <p>{t('apiAccess')}</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/changePassword" className="nav-link">
                    <i className="fas fa-key nav-icon"></i>
                    <p>{t('changePassword')}</p>
                  </Link>
                </li>
              </ul>
            </nav>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                marginRight: '10px',
                color: '#fff',
                cursor: 'pointer',
              }}
            >
              <Link to="/tnc" className="nav-item">
                {this.state.navActive && <>{t('termsAndCondition')}</>}
              </Link>
            </div>
          </div>
        </aside>
        {/* Sidebar component ends here */}


      </div>
    );
  }
}

export default Header;
