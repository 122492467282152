import axios from 'axios';
import React, { Component } from 'react';
import Header from '../Header';

class ConfirmedOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            getOrdersConfirmed: [],
            UserName: localStorage.getItem('user')

        }
    }
    componentDidMount() {
        axios.get(`/users/ConfirmedOrders/${this.state.UserName}`).then(resp => {
            console.log(resp.data)
            this.setState({
                getOrdersConfirmed: resp.data
            })

            const script = document.createElement("script")
            script.src = `js/content.js`
            script.async = true
            document.body.appendChild(script)
        })

    }
    render() {

        return (
            <div className="top">



                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">
                        <div className="content">
                            <div className="container-fluid table-responsive">


                                <div className="card-body">
                                    {this.state.getOrdersConfirmed.length > 0 ?
                                        <table id="example1" className="table table-bordered  zui-table">
                                            <thead>
                                                <tr className="text-center">

                                                    <th className="">Stock#</th>

                                                    <th className="">Avl</th>
                                                    <th className="">Shp</th>
                                                    <th className="">Ct.</th>
                                                    <th className="">Col.</th>
                                                    <th className="">Cla.</th>

                                                    <th>Cut.</th>
                                                    <th>Pol.</th>
                                                    <th>Sym.</th>
                                                    <th>FL.</th>
                                                    <th>Rap</th>
                                                    <th>RapAmt</th>
                                                    <th id="rapnetdiscount">Disc.</th>

                                                    <th>$/ct</th>
                                                    <th>Total$</th>
                                                    <th>MM.</th>
                                                    <th>Lab.</th>
                                                    <th>Cert#</th>
                                                    <th>Shade</th>
                                                    <th>Milky</th>
                                                    <th>SD Comment</th>
                                                    <th>Treat.</th>
                                                    <th>Dep.</th>
                                                    <th>Tab.</th>
                                                    <th>G.THN</th>
                                                    <th>G.THK</th>
                                                    <th>Girdle</th>
                                                    <th>G.CON.</th>
                                                    <th>C.Size</th>
                                                    <th>C.Cond.</th>
                                                    <th>CH</th>
                                                    <th>CA</th>
                                                    <th>PD</th>
                                                    <th>PA</th>
                                                    <th>KTS.</th>
                                                    <th>LI</th>
                                                    <th>C.Com.</th>
                                                    <th className="">Location</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>S.Leng.</th>
                                                    <th>Center Inc.</th>
                                                    <th>Black Inc.</th>
                                                    <th>Comment</th>
                                                    <th>Status</th>

                                                    {/* <th>PDF Export</th> */}

                                                </tr>
                                            </thead>
                                            <tbody >
                                                {this.state.getOrdersConfirmed.map((view, index) => (
                                                    <tr key={view._id} className="text-center">

                                                        <td className="">{view.InwardDetails.Stock}</td>
                                                        <td className="">{view.InwardDetails.Availability ? view.InwardDetails.Availability : '-'}</td>
                                                        <td className="">{view.InwardDetails.Shape}</td>
                                                        <td className="">{view.InwardDetails.Weight}</td>
                                                        {view.InwardDetails.Color ? <td className="">{view.InwardDetails.Color}</td> : <td className="zui-sticky-col4" title={view.InwardDetails.FancyColorIntensity + " " + view.InwardDetails.FancyColorOvertone + " " + view.InwardDetails.FancyColor}>{view.InwardDetails.FancyColorIntensity + " " + view.InwardDetails.FancyColorOvertone + " " + view.InwardDetails.FancyColor}</td>}
                                                        <td className="">{view.InwardDetails.Clarity}</td>
                                                        <td>{view.InwardDetails.CutGrade}</td>
                                                        <td>{view.InwardDetails.Polish}</td>
                                                        <td>{view.InwardDetails.Symmetry}</td>
                                                        <td>{view.InwardDetails.FluorescenceIntensity}</td>
                                                        <td>{view.InwardDetails.Rap}</td>
                                                        <td>{view.InwardDetails.RapAmt}</td>
                                                        <td id="rapnetdiscount">{view.InwardDetails.RapnetDiscount}</td>
                                                        <td>{view.InwardDetails.RapNetPrice}</td>
                                                        <td>{view.InwardDetails.NetValue}</td>
                                                        <td>{view.InwardDetails.Measurements}</td>
                                                        <td>{view.InwardDetails.Lab}</td>
                                                        <td>{view.InwardDetails.Certificate}</td>
                                                        <td>{view.InwardDetails.Shade}</td>
                                                        <td>{view.InwardDetails.Milky}</td>
                                                        <td>{view.InwardDetails.MemberComment}</td>
                                                        <td>{view.InwardDetails.Treatment}</td>


                                                        <td>{view.InwardDetails.Depth}</td>
                                                        <td>{view.InwardDetails.Table}</td>
                                                        <td>{view.InwardDetails.GirdleThin}</td>
                                                        <td>{view.InwardDetails.GirdleThick}</td>
                                                        <td>{view.InwardDetails.Girdle}</td>
                                                        <td>{view.InwardDetails.GirdleCondition}</td>
                                                        <td>{view.InwardDetails.CuletSize}</td>
                                                        <td>{view.InwardDetails.CuletCondition}</td>
                                                        <td>{view.InwardDetails.CrownHeight}</td>
                                                        <td>{view.InwardDetails.CrownAngle}</td>
                                                        <td>{view.InwardDetails.PavilionDepth}</td>
                                                        <td>{view.InwardDetails.PavilionAngle}</td>
                                                        <td>{view.InwardDetails.Keytosymbols}</td>

                                                        <td>{view.InwardDetails.LaserInscription}</td>
                                                        <td>{view.InwardDetails.Certcomment}</td>
                                                        <td className="">{view.InwardDetails.Country}</td>
                                                        <td>{view.InwardDetails.State}</td>
                                                        <td>{view.InwardDetails.City}</td>


                                                        <td>{view.InwardDetails.StarLength}</td>
                                                        <td>{view.InwardDetails.CenterInclusion}</td>
                                                        <td>{view.InwardDetails.BlackInclusion}</td>
                                                        <td>{view.Comment}</td>
                                                        <td>{view.IsAccepted}</td>




                                                        {/* <td><Link onClick={(e) => this.pdfDownload(e, view._id)}><i className="fa fa-download" style={{ color: "#00000080" }}></i></Link></td> */}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        : <h1>No orders</h1>}
                                </div>
                            </div>
                        </div></div></div>
            </div>






















        );
    }
}

export default ConfirmedOrders;