import React, { useContext, useEffect, useState } from 'react';
import { DiamondContext } from '../context/DiamondContext';
import { I18nContext } from '../context/i18nContext';
import './Dashboard.css';
import Header from './Header';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';
import swal from 'sweetalert';

const ParameterDetail = ({ diamondData }) => {

  const { t } = useContext(I18nContext);


  return (
    <div className="parameter-table">
      <table>
        <thead>
          <tr>
            <th>{t('certificateNo')}</th>
            <th>{t('stockNumber')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{diamondData.Certificate}</td>
            <td>{diamondData.Stock}</td>
          </tr>

          <tr>
            <th>{t('shadeName')}</th>
            <th>{t('milky')}</th>
          </tr>
          <tr>
            <td>{diamondData.Shade}</td>
            <td>{diamondData.Milky}</td>
          </tr>

          <tr>
            <th>{t('blackInclusion')}</th>
            <th>{t('whiteInclusion')}</th>
          </tr>
          <tr>
            <td>{diamondData.BlackInclusion}</td>
            <td>-</td>
          </tr>

          <tr>
            <th>{t('measurement')}</th>
            <th>{t('depth')} / {t('table')}</th>
          </tr>
          <tr>
            <td>{diamondData.Measurements}</td>
            <td>{`${diamondData.Depth} / ${diamondData.Table}`}</td>
          </tr>

          <tr>
            <th>{t('nakedEye')}</th>
            <th>{t('size')}</th>
          </tr>
          <tr>
            <td>-</td>
            <td>-</td>
          </tr>

          <tr>
            <th>{t('classification')}</th>
            <th>{t('reportType')}</th>
          </tr>
          <tr>
            <td>-</td>
            <td>{diamondData.ReportType}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const CostInformation = ({ diamondData, newMarkUpState }) => {

  const { t } = useContext(I18nContext);

  return (
    <div className="cost-table">
      <table>
        <tbody>
          <tr>
            <th>{t('intQuote')}:</th>
            <td>{diamondData.Rap}</td>
          </tr>
          <tr>
            <th>{t('carat')}:</th>
            <td>{diamondData.Weight}</td>
          </tr>
          <tr>
            <th>{t('discount')}:</th>
            <td>{diamondData.RapnetDiscount}</td>
          </tr>
          <tr>
            <th>$/{t('carat')}:</th>
            <td>
              {/* {newMarkUpState?.[diamondData.PartyName]?.discount === true
                ? Math.ceil(
                    diamondData.Rap -
                      (Math.abs(diamondData?.VendorDiscount) / 100) *
                        diamondData.Rap
                  )
                : diamondData.Color === ''
                ? Math.ceil(
                    diamondData?.Rap +
                      newMarkUpState?.[diamondData.PartyName]?.FancyMarkup
                  )
                : Math.ceil(
                    diamondData.Rap +
                      newMarkUpState?.[diamondData.PartyName]?.WhiteMarkup
                  )} */}
                  {diamondData.RapNetPrice.toFixed(0)}
            </td>
          </tr>
          <tr>
            <th>{t('purchase')} USD/{t('piece')}:</th>
            <td>
              {/* {newMarkUpState?.[diamondData.PartyName]?.discount === true
                ? Math.ceil(
                    (diamondData.Rap -
                      (Math.abs(diamondData?.VendorDiscount) / 100) *
                        diamondData.Rap) *
                      diamondData.Weight
                  )
                : diamondData.Color === ''
                ? Math.ceil(
                    (diamondData?.Rap +
                      newMarkUpState?.[diamondData.PartyName]?.FancyMarkup) *
                      diamondData.Weight
                  )
                : Math.ceil(
                    (diamondData.Rap +
                      newMarkUpState?.[diamondData.PartyName]?.WhiteMarkup) *
                      diamondData.Weight
                  )} */}
                  {diamondData.NetValue.toFixed(0)}
            </td>
          </tr>
          <tr>
            <th>RMB/{t('carat')}:</th>
            <td>-</td>
          </tr>
          <tr>
            <th>RMB/{t('piece')}:</th>
            <td>-</td>
          </tr>
          <tr>
            <th>{t('exchangeRate')}:</th>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const GenerateQuote = ({ diamondData }) => {
  return (
    <>
      <div>International Quote: $4500.00</div>
      <div>Carat: 1.17</div>
      <div>Pre-sale bonus points: +0</div>
      <div>Pre-sale discount: -97.70</div>
      <div>
        <span>Exchange rate: </span>
        <input type="number" />
      </div>
      <div>
        <span>Magnification: </span>
        <input type="number" />
      </div>
      <div>RMB/Cat: 756</div>
      <div>RMB/capsule: 884</div>
      <button type="button" class="btn btn-primary">
        Quote
      </button>
    </>
  );
};

const Dashboard = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [activeSection, setActiveSection] = useState('parameters');
  const [diamondData, setDiamondData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [newMarkUpState, setNewMarkUpState] = useState();
  const [partyMarkup, setPartyMarkup] = useState();
  const [partyList, setPartyList] = useState();
  const history = useHistory();
  const [formData, setFormData] = useState({
    FullName: '',
    EmailID: '',
    ContactNo: '',
    Message: '',
    Multiple: [], 
  });
  
  const user = localStorage.getItem('user');

  const itemsPerPage = 50;
  const data = useContext(DiamondContext);
  const { t } = useContext(I18nContext);

  useEffect(() => {
    fetchDiamondsData();

    Axios.get('/users/ViewPartyDetails').then((res) => {
      // prints all supplier
      // console.log("/users/ViewPartyDetails response is : ", res.data);
      const markUps = {};
      const newMarkUps = {};
      res.data.forEach((party) => {
        markUps[party.partyName] = party.FancyMarkup;

        if (party.IsActive) {
          newMarkUps[party?.partyName] = {
            FancyMarkup: party?.FancyMarkup,
            Markup: party?.Markup,
            WhiteMarkup: party?.WhiteMarkup,
            discount: party?.discount,
            // VendorDiscount: party?.VendorDiscount,
          };
        }
      });

      setNewMarkUpState(newMarkUps);
      setPartyMarkup(markUps);
      setPartyList(res.data);
    });

    if (data?.diamondData?.record.length === 0) history.push('/searchDiamond');
  }, []);

  useEffect(() => {
    updateDataToDisplay();
  }, [diamondData, currentPage]);

  const fetchDiamondsData = async () => {
    setDiamondData(data.diamondData.record);
  };

  const updateDataToDisplay = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDataToDisplay(diamondData.slice(startIndex, endIndex));
  };

  const handleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
    setActiveSection('parameters'); // Reset to default section when expanded
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log('Data lenght is : ', diamondData.length);
  console.log('Data to display is : ', dataToDisplay);
  const totalPages = Math.ceil(diamondData.length / itemsPerPage);

  const renderMobilePagination = () => {
    const visiblePages = Math.min(5, totalPages); // Show max 5 pages initially
    const startIndex = Math.max(
      Math.min(
        currentPage - Math.floor(visiblePages / 2),
        totalPages - visiblePages + 1
      ),
      1
    );
    const endIndex = startIndex + visiblePages - 1;

    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === totalPages;

    return (
      <div className="mobile-pagination">
        <button
          disabled={prevButtonDisabled}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        {Array.from({ length: visiblePages }, (_, index) => (
          <button
            key={index + startIndex}
            className={currentPage === startIndex + index ? 'active' : ''}
            onClick={() => handlePageChange(startIndex + index)}
          >
            {startIndex + index}
          </button>
        ))}
        <button
          disabled={nextButtonDisabled}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    );
  };

  const csvDownloadAllFilteredRecords = (e) => {
    const data = dataToDisplay;
    var curDate = new Date();
    const fileName =
      curDate.getFullYear() +
      '_' +
      (curDate.getMonth() + 1) +
      '_' +
      curDate.getDate() +
      '_Inward';
    const exportType = 'csv';

    const adaptedData = data.map((inward) => AdaptInwardForCSV(inward));
    exportFromJSON({ data: adaptedData, fileName, exportType });
  };

  const AdaptInwardForCSV = (inward) => {
    const adapted = {};
    const obj = {
      'Stock#': 'Stock',
      Shp: 'Shape',
      'Ct.': 'Weight',
      'Col.': 'Color',
      'Cla.': 'Clarity',
      DNA: 'Video',
      Avl: 'Availability',
      'Cut.': 'CutGrade',
      'Pol.': 'Polish',
      'Sym.': 'Symmetry',
      'Flu.': 'FluorescenceIntensity',
      Rap: 'Rap',
      RapAmt: 'RapAmt',
      RapDis: 'RapnetDiscount',
      '$/ct': 'RapNetPrice',
      Total$: 'NetValue',
      'MM.': 'Measurements',
      'Lab.': 'Lab',
      'Cert#': 'Certificate',
      Shade: 'Shade',
      Milky: 'Milky',
      'SD Comment': 'MemberComment',
      'Treat.': 'Treatment',
      'Dep.': 'Depth',
      'Tab.': 'Table',
      'G.THN': 'GirdleThin',
      'G.THK': 'GirdleThick',
      Girdle: 'Girdle',
      'G.CON.': 'GirdleCondition',
      'C.Size': 'CuletSize',
      'C.Cond.': 'CuletCondition',
      CH: 'CrownHeight',
      CA: 'CrownAngle',
      PD: 'PavilionDepth',
      PA: 'PavilionAngle',
      'KTS.': 'Keytosymbols',
      LI: 'LaserInscription',
      'C.Com.': 'Certcomment',
      Location: 'Country',
      State: 'State',
      City: 'City',
      'S.Leng.': 'StarLength',
      'Center Inc.': 'CenterInclusion',
      'Black Inc.': 'BlackInclusion',
    };

    const keys = Object.keys(obj);
    keys.forEach((key) => {
      adapted[key] = inward[obj[key]];
    });

    adapted['Col.'] = adapted['Col.']
      ? adapted['Col.']
      : inward.FancyColorIntensity +
        ' ' +
        inward.FancyColorOvertone +
        ' ' +
        inward.FancyColor;

    // adapted['$/ct'] = adapted['$/ct']
    //   ? adapted['$/ct']
    //   : this.state.partyMarkup[inward.PartyName] && inward.FancyColorPrice
    //   ? inward.FancyColorPrice + this.state?.partyMarkup[inward.PartyName]
    //   : '';

    // adapted['Total$'] = adapted['Total$']
    //   ? adapted['Total$']
    //   : this.state.partyMarkup[inward.PartyName] && inward.FancyColorPrice
    //   ? (inward.FancyColorPrice + this.state?.partyMarkup[inward.PartyName]) *
    //     inward.Weight
    //   : '';

    adapted['DNA'] =
      'https://shreediamonds.in' + `/Video?Certificate=${inward.Certificate}`;

    return adapted;
  };

  const getData = () => {
    Axios.get(`/users/getUserInformation/${user}`).then((res) => {
      setFormData((prevData) => ({
        ...prevData,
        FullName: res.data.user.FullName,
        EmailID: res.data.user.EmailID,
        ContactNo: res.data.user.ContactNo,
      }));
    });
  };

  const handleChangeMessage = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (diamond, isChecked) => {
    setFormData((prevData) => {
      const updatedMultiple = isChecked
        ? [...prevData.Multiple, diamond.Certificate] // Add certificate if checked
        : prevData.Multiple.filter(
            (cert) => cert !== diamond.Certificate
          ); // Remove certificate if unchecked
      return {
        ...prevData,
        Multiple: updatedMultiple,
      };
    });
  };

  const inquiry = () => {
    Axios.post(`users/inquiry/${user}/${formData.Multiple}`, formData).then((res) => {
      if (res.data.msg.includes('Success')) {
        swal('Message Sent', '', {
          buttons: false,
          icon: 'success',
          timer: 1500,
        });
        setFormData((prevData) => ({
          ...prevData,
          Message: '',
          ContactNo: '',
          Multiple: [],
        }));
        // Close modal here (you can handle modal closing logic as per your need)
      }
    });
  };

  const Addcart = (e) => {
    e.preventDefault();
    Axios.post(`/users/Addcart`, {
      Multiple: formData.Multiple,
      UserID: user,
    }).then((res) => {
      if (res.data.msg === 'Error') {
        swal({
          title: 'Fail!',
          text: 'Inward Already in Cart',
          icon: 'warning',
          timer: 2000,
          button: false,
        });
      } else {
        swal({
          title: 'Done!',
          text: 'Inward Added in Cart',
          icon: 'success',
          timer: 2000,
          button: false,
        });
      }
    });
  }


  return (
    <>
      <div className={{ width: '100%', border: '4px solid red' }}>
        <Header />

        {/* Filter options starts here: Downlaod, ViewCart */}
        <div style={{ marginTop: '25%' }}>
          <div className="row">
            {/* Inquiry */}
            {/* <div className="col-md-2 col-6 d-flex">
              <button
                id="inquiry"
                className="btn btn-info w-100 m-1"
                data-toggle="modal"
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
                &nbsp;{t('inquiry')}
              </button>
            </div>
            <div
              className="modal fade bd-example-modal-lg1"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {t('inquiry')}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              id="Name"
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email ID"
                              id="EmailID"
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact No"
                              id="ContactNo"
                              name="ContactNo"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Certificate"
                              id="Certificate"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              id="message-text"
                              name="Message"
                              placeholder="Message"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-info">
                      {t('inquiry')}
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-2 col-6 d-flex">
              <button
                id="inquiry"
                className="btn btn-info w-100 m-1"
                data-toggle="modal"
                data-target=".bd-example-modal-lg1"
                onClick={getData}
                disabled={formData.Multiple.length === 0}
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
                &nbsp;{t('inquiry')}
              </button>

              <div
                className="modal fade bd-example-modal-lg1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Inquiry</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                value={formData.FullName}
                                readOnly
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email ID"
                                value={formData.EmailID}
                                readOnly
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact No"
                                name="ContactNo"
                                value={formData.ContactNo}
                                onChange={handleChangeMessage}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Certificate"
                                value={formData.Multiple.join(', ')}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="Message"
                                placeholder="Message"
                                rows="8"
                                onChange={handleChangeMessage}
                                value={formData.Message}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={inquiry}
                      >
                        Inquiry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-6 d-flex">
              <button
                className="btn btn-info w-100 m-1"
                onClick={(e) => csvDownloadAllFilteredRecords(e)}
              >
                <i className="fa fa-download"></i>&nbsp;
                {t('downloadCSV')}
              </button>
            </div>

            <div className="col-md-2 col-6 d-flex">
              <Link to="/ViewCart" className="btn btn-info w-100 m-1">
                <i className="fa fa-shopping-cart" />
                &nbsp;&nbsp;{t('viewCart')}
              </Link>
            </div>

            <div className="col-md-2 col-6 d-flex">
              <button
                className="btn btn-info w-100 m-1"
                disabled={formData.Multiple.length === 0}
                onClick={(e) => Addcart(e)}
              >
                <i className="fa fa-shopping-cart"></i>
                &nbsp;&nbsp;Add to Cart
              </button>
            </div>

            {/* <div className="col-md-2 col-6 d-flex">
              <button className="btn btn-info w-100 m-1">
                {t('downloadAll')}
              </button>
            </div> 
            <div className="col-md-2 col-6 d-flex">
              <button className="btn btn-info w-100 m-1">
                <i className="far fa-gem"></i>&nbsp;
                {t('compareStone')}
              </button>
            </div>
            
            */}
          </div>
        </div>

        <div className="main">
          <div className="container">
            <div style={{}}>
              <div className="header">
                <div className="subhead s1">{t('check')}</div>
                <div className="subhead s2">{t('diamDetail')}</div>
                <div className="subhead s3">
                {t('discount')}
                  <svg
                    className="sort-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path d="M7 10l5-5 5 5H7zM7 14l5 5 5-5H7z" />
                  </svg>
                </div>
                <div className="subhead s4">
                  US$/{t('piece')}
                  <svg
                    className="sort-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path d="M7 10l5-5 5 5H7zM7 14l5 5 5-5H7z" />
                  </svg>
                </div>
              </div>
            </div>

            {dataToDisplay.map((diamond) => (
              <div key={diamond._id} style={{ padding: '0px 2%' }}>
                <div className="listelement">
                  <div className="container-left">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(diamond, e.target.checked)
                      }
                    />
                  </div>

                  <div
                    className="container-right"
                    onClick={() => handleExpand(diamond._id)}
                  >
                    <div className="container-r-up">
                      <span className="font-weight-bold detail">
                        <span>{`${diamond?.Weight} `}</span>
                        <span>{`${diamond?.Shape} `}</span>
                      </span>
                      <span className="font-weight-bold">
                        {`${
                          diamond?.Color.toUpperCase() ||
                          diamond?.FancyColor.toUpperCase()
                        } ${diamond?.Clarity} ${diamond?.Polish} ${
                          diamond?.Symmetry
                        } ${diamond?.FluorescenceIntensity}`}
                      </span>

                      <span className="font-weight-bold">{` ${diamond?.Lab}`}</span>
                      <span className="font-weight-bold">{`${diamond?.ReportType}`}</span>

                      <span style={{ marginLeft: '10px' }}>
                        <Link to={`/Video?Certificate=${diamond?.Certificate}`}>
                          <i className="fas fa-video"></i>
                        </Link>
                      </span>
                    </div>

                    <div className="container-r-down">
                      <span>{`${diamond.Country} `}</span>
                      <span className="discount">
                        {typeof diamond?.VendorDiscount === 'number'
                          ? diamond.VendorDiscount.toFixed(2)
                          : diamond?.VendorDiscount || 0}
                      </span>
                      {/* <span
                        className="font-weight-bold"
                        style={{ marginLeft: '10px' }}
                      >{`  ${diamond?.NetValue || 0}$`}</span> */}
                      <span
                        className="font-weight-bold"
                        style={{ marginLeft: '10px' }}
                      >
                        ${diamond?.NetValue.toFixed(0)}
                        {/* {newMarkUpState?.[diamond.PartyName]?.discount === true
                          ? Math.ceil(
                              (diamond.Rap -
                                (Math.abs(diamond?.VendorDiscount) / 100) *
                                  diamond.Rap) *
                                diamond.Weight
                            )
                          : diamond.Color === ''
                          ? Math.ceil(
                              (diamond?.Rap +
                                newMarkUpState?.[diamond.PartyName]
                                  ?.FancyMarkup) *
                                diamond.Weight
                            )
                          : Math.ceil(
                              (diamond.Rap +
                                newMarkUpState?.[diamond.PartyName]
                                  ?.WhiteMarkup) *
                                diamond.Weight
                            )} */}
                      </span>
                    </div>
                  </div>
                </div>

                {expandedId === diamond._id && (
                  <div className="diamond-desc">
                    <div className="modal-options">
                      <div
                        className={`option ${
                          activeSection === 'parameters' ? 'active' : ''
                        }`}
                        onClick={() => setActiveSection('parameters')}
                      >
                        {t('paramDetail')}
                      </div>

                      <div
                        className={`option ${
                          activeSection === 'cost' ? 'active' : ''
                        }`}
                        onClick={() => setActiveSection('cost')}
                      >
                        {t('costInfo')}
                      </div>
                    </div>

                    <div className="modal-content">
                      {activeSection === 'parameters' && (
                        <ParameterDetail diamondData={diamond} />
                      )}

                      {activeSection === 'cost' && (
                        <CostInformation
                          diamondData={diamond}
                          newMarkUpState={newMarkUpState}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="pagination">
              {/* Mobile pagination */}
              {window.innerWidth <= 480 && renderMobilePagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
