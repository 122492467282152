import React, { useContext } from 'react';
import PageHeader from '../PageComponents/PageHeader';
import { I18nContext } from '../../../context/i18nContext';

function AboutUs() {
  const { t } = useContext(I18nContext);

  return (
    <div
      style={{
        width: '100%',
        paddingBottom: '2rem',
      }}
    >
      <PageHeader title={`${t('aboutUs')}`} />
      <div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <div style={{ fontSize: '2rem' }}>{t('ourStory')}</div>
          <div
            style={{
              fontSize: '1.25rem',
              width: '80%',
              margin: 'auto',
              marginTop: '10px',
              marginBottom: '2rem',
            }}
          >
           {t('ourStoryDesc')}
          </div>
          <hr />
        </div>

        <div
          style={{
            textAlign: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <div style={{ fontSize: '2rem' }}>{t('ourVision')}</div>
          <div
            style={{
              fontSize: '1.25rem',
              width: '80%',
              margin: 'auto',
              marginTop: '10px',
              marginBottom: '2rem',
            }}
          >
            {t('ourVisionDesc')}
          </div>
          <hr />
        </div>
        <div
          style={{
            textAlign: 'center',
            paddingTop: '1rem',
            paddingBottom: '3rem',
          }}
        >
          <div style={{ fontSize: '2rem' }}>{t('ourMission')}</div>
          <div
            style={{
              fontSize: '1.25rem',
              width: '80%',
              margin: 'auto',
              marginTop: '10px',
              marginBottom: '2rem',
            }}
          >
            {t('ourMissionDesc')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
