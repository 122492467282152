import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import swal from 'sweetalert';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            EmailID: '',
            otp: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(event) {
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleSubmit(e) {
        e.preventDefault()



        localStorage.setItem('EmailID', this.state.EmailID)
        if (this.state.EmailID == '') {
            swal('Please Enter Email', '', 'error')


        }
        else {
            Axios.post('/forgotpassword', this.state).then((res) => {
                this.setState({
                    otp: res.data.otp
                })
                if (res.data.msg) {
                    swal('Email Sent', '', 'success')
                    localStorage.setItem('otp', this.state.otp)
                    
                    this.props.history.push('/checkOTP')
                }
                else {
                    swal('Please Enter Valid Email', '', 'error')
                }

            })
        }
    }
    componentDidMount() {

    }
    render() {
        const { EmailID } = this.state
        return (
            <div>
                <center>
                    <div className="content" style={{ marginTop: "15%" }}>
                        <div className="container-fluid">
                            <div className="login-box">
                                <div className="card card-outline card-info">
                                    <div className="card-header text-center">
                                        <h3><b>Forgot&nbsp;</b>Password</h3>
                                    </div>
                                    <div className="card-body">

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="email" className="form-control" name="EmailID" placeholder="Enter Email Address" value={EmailID} onChange={this.handleChange} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-envelope"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-info btn-block">Forgot Password</button>
                                                </div>

                                            </div>
                                        </form>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        );
    }
}

ForgotPassword.propTypes = {

};

export default ForgotPassword;