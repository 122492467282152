export const footerResponsive = (windowSize) => {
  return windowSize <= 800
    ? {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '2.5rem',
        padding: '4rem',
        fontFamily: 'Arial, sans-serif',
      }
    : {};
};
