import axios from 'axios';
import React, { Component, Fragment } from 'react';
import $ from 'jquery';

class DisplayVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
            videoURL: '',
            Inward: [],

        }
        
    }
    componentDidMount() {

        $(this).bind('contextment',()=>{
            return false;
        })


        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
          });

        // document.oncontextmenu = function (e) {
       
        //     if (e.button == 2) {
        //         e.preventDefault();
        //         return false;
        //     }
        
        // }  
        const params = new URLSearchParams(window.location.search)
        this.state.image = parseInt(params.get('Certificate'))
        axios.get(`/users/getDiamondVideo/${this.state.image}`).then(res => {
            this.setState({
                videoURL: res.data.diamondvideo,
                Inward: res.data.inward
            })
            console.log(this.state.videoURL)
            if(this.state.videoURL)
            {
                // window.frames['iframe'].contentDocument.oncontextmenu=function(){
                //     return false;
                // }
            }
        })
    
        // $('.iframe').load(this.state.diamondURL + '.product_video_ifram_top right_zero');
        // $('#upload_target').contents().find('body').append($("<img/>").attr("src", this.state.diamondURL).attr("title", "sometitle"));

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            {this.state.videoURL != "" ? <iframe id="iframe1" src={this.state.videoURL} height="600" width="660" className="iframe" ></iframe> : <img src="../dist/img/No_Image_Available.png" alt="No Image" height="500"/>}
                            {/* <img src="../dist/img/No_Image_Available.png" alt={this.state.diamondURL} /> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="pt-2">
                            <table className="zui-table table table-bordered " style={{ display: "table-cell" }}>
                                {this.state.Inward.map(view => (

                                    <Fragment>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <tr className="text-center">
                                                    <th>Shape</th>
                                                    <td>{view.Shape}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Weight</th>
                                                    <td>{view.Weight}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Clarity</th>
                                                    <td>{view.Clarity}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Color</th>
                                                    <td>{view.Color}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Cut Grade</th>
                                                    <td>{view.CutGrade}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Polish</th>
                                                    <td>{view.Polish}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Symmentry</th>
                                                    <td>{view.Symmetry}</td>
                                                </tr>
                                                <tr>
                                                    <th>Flu.Color</th>
                                                    <td>{view.FluresenceColor ? view.FluresenceColor : '-'}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Lab</th>
                                                    <td>{view.Lab}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Certificate No.</th>
                                                    <td>{view.Certificate}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Measurements</th>
                                                    <td>{view.Measurements}</td>
                                                </tr>
                                            </div>
                                            <div className="col-md-6">
                                                <tr className="text-center">
                                                    <th>Crown Angle</th>
                                                    <td>{view.CrownAngle}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Crown Height</th>
                                                    <td>{view.CrownHeight}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Pavilion Angle</th>
                                                    <td>{view.PavilionAngle}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Pavilion Depth</th>
                                                    <td>{view.PavilionDepth}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Table</th>
                                                    <td>{view.Table}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Depth</th>
                                                    <td>{view.Depth}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Girdle</th>
                                                    <td>{view.Girdle}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Ratio</th>
                                                    <td>{view.Ratio}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Black Inclusion</th>
                                                    <td>{view.BlackInclusion}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Shade</th>
                                                    <td>{view.Shade}</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <th>Milky</th>
                                                    <td>{view.Milky}</td>
                                                </tr>
                                            </div>
                                        </div>


                                    </Fragment>

                                ))}

                            </table>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default DisplayVideo;