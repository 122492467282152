import React, { useContext } from 'react';
import SubWhyChooseUs from '../Landing/components/SubWhyChooseUs';
import { I18nContext } from '../../../context/i18nContext';

function HowItWorks({ windowSize, content }) {
  const image1 = '/Illustrations/realTimeInventory.png';
  const image2 = '/Illustrations/Get-Order.png';
  const image3 = '/Illustrations/QUALITY-CHECK.png';
  const image4 = '/Illustrations/DELIVERY.png';
  const { t } = useContext(I18nContext);

  return (
    <div style={{ marginTop: '3rem' }}>
      <h2
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '1.875rem',
        }}
      >
        {t('howItWorks')}
      </h2>
      <p
        style={{
          marginTop: '0.5rem',
          textAlign: 'center',
          color: '#4B5563',
        }}
      >
        {t('sellDiam')}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SubWhyChooseUs
        img={image1}
          windowSize={windowSize}
          title={`${t('step')} 1 : ${t('s1')}`}
          textHeader={
            `${t('s1h')}`
          }
          text={
            `${t('s1t')}`
          }
          directionRev={true}
        />
        <SubWhyChooseUs
        img={image2}
          windowSize={windowSize}
          title={`${t('step')} 2 : ${t('s2')}`}
          textHeader={`${t('s2h')}`}
          text={
            `${t('s2t')}`
          }
        />
        <SubWhyChooseUs
        img={image3}
          windowSize={windowSize}
          title={`${t('step')} 3 : ${t('s3')}`}
          text={
            `${t('s3t')}`
          }
          directionRev={true}
        />
        <SubWhyChooseUs
        img={image4}
          windowSize={windowSize}
          title={`${t('step')} 4 : ${t('s4')}`}
          text={
            `${t('s4t')}`
          }
          noHr={true}
        />
      </div>
    </div>
  );
}

export default HowItWorks;
