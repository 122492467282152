import React from 'react';
import PageHeader from './PageComponents/PageHeader';

function Events() {
  return (
    <div
      style={{
        width: '100%',
        paddingBottom: '2rem',
      }}
    >
      <PageHeader title="Events" />
      <div
        style={{
          width: '50%',
          margin: 'auto',
        }}
      ></div>
      <div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '2rem' }}>Events</div>
          <div
            style={{
              fontSize: '1.25rem',
              width: '80%',
              margin: 'auto',
              marginTop: '10px',
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod
          </div>

          <hr />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '2rem' }}>Our Vision</div>
          <div
            style={{
              fontSize: '1.25rem',
              width: '80%',
              margin: 'auto',
              marginTop: '10px',
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quia, quae voluptates voluptate quod
          </div>
        </div>
      </div>
      <div
        style={{
          width: '80%',
          margin: 'auto',
          display: 'flex',
          marginTop: '2rem',
        }}
      >
        <div style={{ width: '50%', fontSize: '3rem' }}>
          Transparency, tech & <br /> trust is how we shine
        </div>
        <div style={{ width: '50%' }}>
          Our world runs on the three wheels of transparency, technology and
          trust. We channel all our resources to become a catalyst of change and
          streamline the supply chain in the gems and jewellery industry. <br />
          <br />
          We aim to bring all segments of polished diamonds online at a single
          click and lead jewellers globally to buy diamonds online with
          confidence and comfort on our platform.
        </div>
      </div>
    </div>
  );
}

export default Events;
