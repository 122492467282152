import React, { useContext, useState } from 'react';
import { GrFacebook, GrInstagram, GrLinkedin, GrTwitter } from 'react-icons/gr';
import { footerResponsive } from '../../../responsiveFunctions/FooterResponsive';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { I18nContext } from '../../../context/i18nContext';

function Footer({ windowSize, setCurrentPath }) {
  const { t } = useContext(I18nContext);
  const history = useHistory();

  const [height] = useState(window.innerHeight);
  console.log(height);
  console.log(window.screenY);
  return (
    <>
      <hr
        style={{
          marginBottom: '1.3rem',
        }}
      />

      <footer
        style={{
          background: '#fff',
          color: '#000000',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '2.5rem',
          padding: '4rem',
          paddingTop: '1rem',
          fontFamily: 'Arial, sans-serif',
          ...footerResponsive(windowSize),
        }}
      >
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.875rem',
              marginBottom: '0.5rem',
              cursor: 'pointer',
              color: 'rgb(19, 141, 201)',
            }}
            onClick={() => setCurrentPath('home')}
          >
            Shree Diamonds
          </div>
          <div style={{ marginBottom: '1.2rem', marginTop: '-0.6rem' }}>
            Surat, Gujarat, India
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
            }}
          >
            +91 9898773044
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
            }}
          >
            info@shreediamonds.com
          </div>
        </div>

        <div>
          <div
            style={{
              fontSize: '1rem',
              color: '#000000',
              marginBottom: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {t('company')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('aboutUs')}
          >
            {t('aboutUs')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('whyUs')}
          >
            {t('whyUs')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('forSuppliers')}
          >
            {t('forSupplier')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/login')}
          >
            {t('login')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/addCustomer')}
          >
            {t('signup')}
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: '1rem',
              color: '#000000',
              marginBottom: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {t('information')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('contactUs')}
          >
            {t('demo')}
          </div>
          {/* <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('events')}
          >
            Events
          </div> */}
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('faq')}
          >
            {t('faq')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('privacyPolicy')}
          >
            {t('privacyPolicy')}
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPath('tnc')}
          >
            {t('termsAndCondition')}
          </div>
        </div>
      </footer>

      <div
        style={{
          backgroundColor: '#138dc9',
          padding: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#d3d3d3',
        }}
      >
        <div>© 2023 Shree Diamonds. All Rights Reserved.</div>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <GrFacebook style={{ marginRight: '0.5rem' }} />
          <GrLinkedin style={{ marginRight: '0.5rem' }} />
          <GrTwitter style={{ marginRight: '0.5rem' }} />
          <GrInstagram />
        </div>
      </div>
    </>
  );
}

export default Footer;

/* 

        <div>
          <div
            style={{
              fontSize: '1rem',
              color: '#FFFFFF',
              marginBottom: '0.5rem',
            }}
          >
            OUR PRODUCTS
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.25rem',
              marginBottom: '0.125rem',
            }}
          >
            Natural Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              fontSize: '0.875rem',
              cursor: 'pointer',
            }}
          >
            White Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              fontSize: '0.875rem',
              cursor: 'pointer',
            }}
          >
            Fancy colored Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              fontSize: '0.875rem',
              cursor: 'pointer',
            }}
          >
            Parcel Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              marginTop: '0.5rem',
              marginBottom: '0.125rem',
            }}
          >
            Lab Grown Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              fontSize: '0.875rem',
              cursor: 'pointer',
            }}
          >
            White Diamonds
          </div>
          <div
            style={{
              color: '#635e5e',
              fontSize: '0.875rem',
              cursor: 'pointer',
            }}
          >
            Fancy colored Diamonds
          </div>
        </div>


*/
