import React, { Component } from 'react';
import Header from '../Header';
import Axios from 'axios'

class Stoneoffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getDemand: [],
            Weight: '',
            Color: '',
            Clarity: '',
            Price: '',
            UserID: localStorage.getItem('user'),
        }
    }
    componentDidMount() {
        Axios.get(`/users/getDemand/${this.state.UserID}`).then(res => {
            this.setState({
                getDemand: res.data.Offer,
                Weight: res.data.Weight,
                Color: res.data.Color,
                Clarity: res.data.Clarity,
                Price: res.data.Price
            })
            const script = document.createElement("script")
            script.src = `js/content.js`
            script.async = true
            document.body.appendChild(script)
        })
    }
    render() {
        return (
            <div >
                <Header />
                <div className="wrapper pt-5">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">Your Offers</div>
                                </div>
                                <div className="card-body">
                                    {this.state.getDemand.length != 0 ? <div className="table-responsive">
                                        < table id="example1" className="table table-striped table-bordered" >
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Certificate</th>
                                                    <th>Weight</th>
                                                    <th>Color</th>
                                                    <th>Clarity</th>
                                                    <th>Price</th>
                                                    <th>Discount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.getDemand.map((view, index) => (
                                                    <tr>
                                                        <td>{view.Certificate}</td>
                                                        <td>{this.state.Weight[index]}</td>
                                                        <td>{this.state.Color[index]}</td>
                                                        <td>{this.state.Clarity[index]}</td>
                                                        <td>{this.state.Price[index]}</td>
                                                        <td>{view.Discount}</td>
                                                        {view.IsPending === true ? <td>Pending</td> : view.Status === true ? <td>Approved</td> : <td>Rejected</td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> : <div className="text-center">No Data Available</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

export default Stoneoffer;