import React, { useContext, useState } from 'react';
import Header from './Header';
import { useHistory } from 'react-router-dom';
import './ChooseDiamondType.css'; // Make sure to create and import this CSS file
import { DiamondContext } from '../context/DiamondContext';
import { I18nContext } from '../context/i18nContext';

const ChooseDiamondType = () => {
  const { selectedDiamond, handleSelectDiamond } = useContext(DiamondContext);
  const { t } = useContext(I18nContext);
  const history = useHistory();

  const handleSelect = (type) => {
    handleSelectDiamond(type);
    history.push('/searchDiamond');
  };

  const diamondTypes = [
    {
      title: t('white'),
      type: 'Natural',
      imageUrl: 'https://www.svgrepo.com/show/1875/diamond.svg',
    },
    {
      title: t('fancy'),
      type: 'Natural',
      imageUrl: 'https://www.svgrepo.com/show/233273/diamond.svg',
    },
    {
      title: t('labGrownWhite'),
      type: 'Labgrown',
      imageUrl:
        'https://www.vectorkhazana.com/assets/images/products/Diamond_Svg.png',
    },
    {
      title: t('labGrownFancy'),
      type: 'Labgrown',
      imageUrl: 'https://www.svgrepo.com/show/247990/diamond.svg',
    },
  ];

  return (
    <>
      <Header />
      <div className="container">
        <div className="d-flex flex-wrap justify-content-between align-items-center diamond-container">
          {diamondTypes.map((diamond, index) => (
            <div
              key={index}
              className={`diamond-type ${
                selectedDiamond === diamond.title ? 'selected' : ''
              }`}
              onClick={() => handleSelect(diamond.type)}
            >
              <img
                src={diamond.imageUrl}
                alt={diamond.title}
                className="img-fluid"
              />
              <div className="diamond-title">{diamond.title}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChooseDiamondType;
