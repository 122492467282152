import React, { useContext, useEffect, useState } from 'react';
import { HiPhone } from 'react-icons/hi';
import { FiCrosshair, FiMenu } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import {
  responsiveHeader,
  responsiveNavigation,
} from '../../../responsiveFunctions/HeaderResponsive';
import useWindowSize from '../../../Hooks/useWindowSize';
import { RxCross1 } from 'react-icons/rx';
import { I18nContext } from '../../../context/i18nContext';
const activeRouteStyle = {
  background: '#efefef',
  paddingTop: '.35rem',
  paddingBottom: '.35rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderRadius: '0.4rem',
  color: 'black',
};
const Header = ({ windowSize, setCurrentPath, isFixed }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, changeLanguage } = useContext(I18nContext);
  let history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChangeLanguage = (event) => {
    changeLanguage(event.target.value);
  };

  return (
    <div>
      {/* <div
        style={{
          background: '#fff',
          padding: '0.2rem',
          paddingRight: '1rem',
          fontSize: '0.875rem',
          color: '#4B5563',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          ...responsiveHeader(windowSize),
        }}
      >
        <HiPhone style={{ marginRight: '0.5rem' }} />
        +91 12345-67890
      </div> */}

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height:"5rem",
          padding: '0.625rem',
          paddingTop: '1rem',
          ...responsiveNavigation(windowSize),
        }} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: windowSize > 800 ? '5rem' : '6rem',
          padding: '0.625rem',
          paddingTop: '1rem',
          ...(isFixed && {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            background: '#fff',
            transition: 'top 0.1s ease-in-out',
            zIndex: '1000',
          }),
          ...responsiveNavigation(windowSize),
        }}
      >
        <div
          style={{
            fontWeight: '600',
            fontSize: '1.25rem',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => setCurrentPath('home')}
        >
          <img
            src="assets/logo-full.jpg"
            width="350px"
            style={{
              position: 'absolute',
              paddingLeft: '1rem',
              paddingTop: '0.4rem',
              top: windowSize > 800 ? '-0.8rem' : '-1.0rem',
            }}
            alt="Logo"
          />
          {/* <div
            style={{
              position: 'absolute',
              marginLeft: window <= 800 ? '100px' : '90px',
              marginTop: window <= 800 ? '0.2rem' : '0',
              fontSize: window <= 800 ? '1.4rem' : '2rem',
              fontWeight: '400',
              top: '-10px',
              textTransform: 'uppercase',
            }}
          >
            Shree&nbsp;Diamonds
          </div> */}
        </div>

        {/* Sidebar for mobile devices */}
        {windowSize <= 800 && (
          <>
            <FiMenu
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.5rem',
              }}
              onClick={menuToggle}
            />
            {isMenuOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  height: '100%',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  zIndex: '999',
                }}
              >
                <div
                  style={{
                    paddingTop: '2rem',
                    paddingLeft: '2rem',
                    width: '300px',
                    height: '100%',
                    background: '#FFFFFF',
                    padding: '1rem',
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      color: '#4B5563',
                      fontSize: '1.5rem',
                      textAlign: 'right',
                      marginBottom: '0.5rem',
                    }}
                    onClick={menuToggle}
                  >
                    <RxCross1 />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        setCurrentPath('home');
                        history.push('/');
                        menuToggle();
                      }}
                    >
                      {t('home')}
                    </div>
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        setCurrentPath('aboutUs');
                        history.push('aboutUs');
                        menuToggle();
                      }}
                    >
                      {t('aboutUs')}
                    </div>
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        setCurrentPath('whyUs');
                        history.push('whyUs');

                        menuToggle();
                      }}
                    >
                      {t('whyUs')}
                    </div>
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        setCurrentPath('forSuppliers');
                        history.push('forSuppliers');

                        menuToggle();
                      }}
                    >
                      {t('forSupplier')}
                    </div>
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        setCurrentPath('contactUs');
                        history.push('contactUs');

                        menuToggle();
                      }}
                    >
                      {t('contactUs')}
                    </div>
                    <div
                      style={{ cursor: 'pointer', color: '#4B5563' }}
                      onClick={() => {
                        history.push('/login');
                        menuToggle();
                      }}
                    >
                      {t('login')}
                    </div>
                    <div
                      style={{
                        background: '#635e5e',
                        color: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '9999px',
                        padding: '0.375rem',
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (currentPath.includes('forSuppliers')) {
                          window.open(
                            'https://admin.shreediamonds.in/Supplier',
                            '_blank'
                          );
                        } else {
                          history.push('/login');
                          menuToggle();
                        }
                      }}
                    >
                      {`${t('joinNow')}! It's Free`}
                    </div>
                    <div>
                      Select Language:
                      <select onChange={handleChangeLanguage}>
                        <option value="en">English</option>
                        <option value="zh">中文</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* {windowSize > 800 && (
          <div
            style={{ display: 'flex', gap: '1.5rem', alignItems: 'center'}}
          >
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                setCurrentPath('home');
                history.push('');
              }}
            >
              Home
            </div>
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                setCurrentPath('aboutUs');
                history.push('aboutUs');
              }}
            >
              About Us
            </div>
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                setCurrentPath('whyUs');
                history.push('whyUs');
              }}
            >
              Why Us
            </div>
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                setCurrentPath('forSuppliers');
                history.push('forSuppliers');
              }}
            >
              For Suppliers
            </div>
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                setCurrentPath('contactUs');
                history.push('contactUs');
              }}
            >
              Contact Us
            </div>
            <div
              style={{ cursor: 'pointer', color: '#4B5563' }}
              onClick={() => {
                history.push('/login');
              }}
            >
              Login
            </div>
            <div
              style={{
                background: '#1F2937',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '9999px',
                padding: '0.375rem',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                history.push('/login');
              }}
            >
              Join Now! It's Free
            </div>
          </div>
        )} */}

        {/* Header for Desktop devices */}
        {windowSize > 800 && (
          <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
            <div>
              Select Language:
              <select onChange={handleChangeLanguage}>
                <option value="en">English</option>
                <option value="zh">中文</option>
              </select>
            </div>
            <div
              style={{
                cursor: 'pointer',
                fontSize: '1.2rem',
                color: '#4B5563',
                width: 'auto',
                ...(currentPath === '/' && activeRouteStyle),
              }}
              onClick={() => {
                setCurrentPath('home');
                history.push('');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('home')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.15rem',
                ...(currentPath === '/aboutUs' && activeRouteStyle),
              }}
              onClick={() => {
                setCurrentPath('aboutUs');
                history.push('aboutUs');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('aboutUs')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.15rem',
                ...(currentPath === '/whyUs' && activeRouteStyle),
              }}
              onClick={() => {
                setCurrentPath('whyUs');
                history.push('whyUs');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('whyUs')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.15rem',
                ...(currentPath === '/forSuppliers' && activeRouteStyle),
              }}
              onClick={() => {
                setCurrentPath('forSuppliers');
                history.push('forSuppliers');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('forSupplier')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.15rem',
                ...(currentPath === '/contactUs' && activeRouteStyle),
              }}
              onClick={() => {
                setCurrentPath('contactUs');
                history.push('contactUs');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('contactUs')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: '#4B5563',
                fontSize: '1.15rem',
                ...(currentPath === '/login' && activeRouteStyle),
              }}
              onClick={() => {
                history.push('/login');
              }}
              onMouseOver={(e) => {
                e.target.style.color = '#69afcd';
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = '300';
                e.target.style.color = '#4B5563';
              }}
            >
              {t('login')}
            </div>
            <div
              style={{
                background: '#000',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '9999px',
                padding: '0.7rem',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                console.log({ currentPath });
                if (currentPath.includes('forSuppliers')) {
                  window.open(
                    'https://admin.shreediamonds.in/Supplier',
                    '_blank'
                  );
                } else history.push('/login');
              }}
            >
              {`${t('joinNow')} ! It's Free`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
