export const featDisplayResponsive = (windowSize) => {
  return windowSize >= 800
    ? {
        marginLeft: '7rem',
        marginRight: '7rem',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E5E7EB',
        paddingBottom: '5rem',
        marginTop: '2.25rem',
      }
    : {
        marginLeft: '2rem',
        marginRight: '2rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '3rem',
        borderBottom: '1px solid #E5E7EB',
        paddingBottom: '5rem',
        marginTop: '2.25rem',
      };
};

export const featDisplayResponsive2 = (windowSize) => {
  return windowSize >= 800
    ? {
        marginLeft: '7rem',
        marginRight: '7rem',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E5E7EB',
        paddingBottom: '5rem',
        marginTop: '2.25rem',
      }
    : {
        marginLeft: '1rem',
        marginRight: '1rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gap: '1rem',
        marginTop: '2.25rem',
      };
};

export const SubWhyChooseUs_ContainerResponsive = (windowSize) => {
  return windowSize <= 800
    ? {
        flexDirection: 'column',
        margin: 'auto',
      }
    : {};
};

export const SubWhyChooseUs_TitleResponsive = (windowSize) => {
  return windowSize >= 800
    ? {
        width: '100%',
      }
    : {
        width: '100%',
      };
};

export const SubWhyChooseUs_TextResponsive = (windowSize) => {
  return windowSize >= 800
    ? { width: '100%', color: '#4B5563', textAlign: 'center' }
    : {
        width: '100%',
        color: '#4B5563',
        marginBottom: '1rem',
        textAlign: 'center',
      };
};

export const FeaturesAndBenefits_ContainerResponsive = (windowSize) => {
  return windowSize <= 800
    ? {
        flexDirection: 'column',
        alignItems: 'flex-start',
      }
    : {};
};

export const FeaturesAndBenefits_PlaceHolderResponsive = (windowSize) => {
  return windowSize <= 800
    ? {
        width: '100%',
      }
    : {};
};
