import React, { useContext, useState } from 'react';
import {
  SubWhyChooseUs_TextResponsive,
  SubWhyChooseUs_TitleResponsive,
} from '../../../responsiveFunctions/LandingResponsive';
import { I18nContext } from '../../../context/i18nContext';
import { t } from 'i18next';

export const AccordionItem = ({ title, content, windowSize }) => {
  const [isOpen, setIsOpen] = useState(false);


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        width: windowSize < 800 ? `${parseInt(windowSize / 1.2)}px` : '800px',
      }}
    >
      <div
        style={{
          background: '#f4f4f4',
          padding: '15px',
          borderRadius: '10px',
          color: isOpen ? '#fff' : '#000',
          cursor: 'pointer',
          ...SubWhyChooseUs_TitleResponsive(windowSize),
          transition: 'background-color 0.3s ease',
          backgroundColor: isOpen ? 'rgb(75, 85, 99)' : '#e0e0e0',
        }}
        onClick={handleClick}
      >
        {title}
      </div>
      {isOpen && (
        <div
          style={{
            padding: '10px',
            overflow: 'hidden',
            ...SubWhyChooseUs_TextResponsive(windowSize),
            maxHeight: isOpen ? '1000px' : '0px',
            transition: 'max-height 0.5s ease',
            animation: isOpen ? 'slide-down 0.5s ease' : 'slide-up 0.5s ease',
            animationFillMode: 'forwards',
            textAlign: 'left',
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

const FaqSection = ({ windowSize }) => {
  const faqs = [
    [
      t('supFq1'),
      t('supFa1')
    ],
    [
      t('supFq2'),
      t('supFa2')
    ],
    [
      t('supFq3'),
      t('supFa3')
    ],
    [
      t('supFq4'),
      t('supFa4')
    ],
    [
      t('supFq5'),
      t('supFa5')
    ],
  ];

  return (
    <div
      style={{
        marginTop: '3rem',
        marginLeft: windowSize > 800 ? '12rem' : '1rem',
        marginRight: windowSize > 800 ? '12rem' : '1rem',
        marginBottom: '3rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{}}>{ t('faq') }</h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}
        >
          {faqs.map((faq, index) => (
            <AccordionItem
              title={faq[0]}
              content={<p>{faq[1]}</p>}
              windowSize={windowSize}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
