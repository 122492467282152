import React, { useContext } from 'react';
import PageHeader from '../PageComponents/PageHeader';
import useWindowSize from '../../../Hooks/useWindowSize';
import { I18nContext } from '../../../context/i18nContext';

function WhyUsPoints({ title, tagLine, size, img }) {
  return (
    <div
      style={{
        display: 'flex',
        width: size < 800 ? '100%' : '30%',
        marginTop: size < 800 ? '1rem' : '0',
        flexDirection: 'column',
        marginLeft: size < 800 ? 0 : '30px',
        marginRight: size < 800 ? 0 : '30px',
        border: '1px solid #E5E7EB',
        padding: '1rem',
        borderRadius: '0.375rem',
      }}
    >
      <div
        style={{
          // background: '#4B5563',
          height: '4rem',
          borderRadius: '0.375rem',
          width: '4rem',
          marginBottom: '2rem',
        }}
      >
        <img src={img} alt="logo" width={100} />
      </div>
      <div style={{ fontSize: '1.125rem', fontWeight: '600' }}>{title}</div>
      <div style={{ color: '#4B5563' }}>{tagLine}</div>
    </div>
  );
}

function WhyUs() {
  const image1 = '/Logos/Comprehensive-Diamond-Inventory.png';
  const image2 = '/Logos/Streamlined-Buying-Process.png';
  const image3 = '/Logos/Trust-and-Transparency.png';
  const image4 = '/Logos/Dedicated-Customer-Support.png';
  const image5 = '/Logos/Industry-Insights-and-Collaboration.png';
  const size = useWindowSize();
  const { t } = useContext(I18nContext);

  return (
    <div
      style={{
        width: '100%',
        paddingBottom: '2rem',
      }}
    >
      <PageHeader title={`${t('whyUs')}`} />

      <div>
        <div style={{ textAlign: 'center' }}>
          {/* <div style={{ fontSize: '2rem' }}>Why Us</div> */}
          <div
            style={{
              fontSize: '1.25rem',
              width: size < 800 ? '90%' : '50%',
              margin: 'auto',
              marginTop: '3rem',
              marginBottom: '3rem',
            }}
          >
            {t('whyUsDesc')}
            <hr
              style={{
                marginTop: '3rem',
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          marginTop: '2rem',
          flexDirection: size < 800 ? 'column' : 'row',
        }}
      >
        <WhyUsPoints
          title={`${t('whyh1')}`}
          tagLine={`${t('whyd1')}`}
          size={size}
          img={image1}
        />
        <WhyUsPoints
          title={`${t('whyh2')}`}
          tagLine={`${t('whyd2')}`}
          size={size}
          img={image2}
        />
      </div>
      <div
        style={{
          width: '80%',
          display: 'flex',
          margin: 'auto',
          marginTop: size < 800 ? '0' : '2rem',
          flexDirection: size < 800 ? 'column' : 'row',
        }}
      >
        <WhyUsPoints
          title={`${t('whyh3')}`}
          tagLine={`${t('whyd3')}`}
          size={size}
          img={image3}
        />
        <WhyUsPoints
          title={`${t('whyh4')}`}
          tagLine={`${t('whyd4')}`}
          size={size}
          img={image4}
        />
        <WhyUsPoints
          title={`${t('whyh5')}`}
          tagLine={`${t('whyd5')}`}
          size={size}
          img={image5}
        />
      </div>
      <hr
        style={{
          width: '80%',
          margin: 'auto',
          marginTop: '2rem',
        }}
      />
      <div
        style={{
          width: '80%',
          margin: 'auto',
          display: 'flex',
          flexDirection: size < 800 ? 'column' : 'row',
          marginTop: '2rem',
        }}
      >
        <div
          style={{
            width: size < 800 ? '100%' : '50%',
            fontSize: size < 800 ? '2rem' : '3rem',
          }}
        >
          {`${t('whysh1')}`} <br /> {`${t('whysh2')}`}
        </div>
        <div style={{ width: size < 800 ? '100%' : '50%' }}>
          {`${t('whysd1')}`}
          <br />
          {`${t('whysd2')}`}
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
