import Axios from 'axios';
import React, { Component } from 'react';
import swal from 'sweetalert';
import Header from '../Components/Header';


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            oldPassword: '',
            confirmPassword: '',

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        Axios.post(`/changePassword/${localStorage.getItem('user')}`, this.state).then(res => {
           
            {
                swal(res.data.success, '', 'success')
            }
        })
    }
    componentDidMount() {

    }


    render() {
        return (
            <div className="top">
                <Header />
                <center>
                    <div className="content" style={{ marginTop: "5%" }}>
                        <div className="container-fluid">
                            <div className="login-box">
                                <div className="card card-outline card-info">
                                    <div className="card-header text-center">
                                        <h3><b>Change&nbsp;</b>Password</h3>
                                    </div>
                                    <div className="card-body">

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" name="oldPassword" value={this.state.oldPassword} onChange={this.handleChange} placeholder="Old Password" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" name="newPassword" value={this.state.newPassword} onChange={this.handleChange} placeholder="New Password" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} placeholder="Confirm Password" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-info btn-block">Change password</button>
                                                </div>

                                            </div>
                                        </form>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </center>

            </div>
        );
    }
}



export default ChangePassword;