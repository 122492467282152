import { useContext } from 'react';
import { AccordionItem } from '../Suppliers/FaqSection';
import { I18nContext } from '../../../context/i18nContext';

export const FaqSectionHome = ({ windowSize }) => {
  const { t } = useContext(I18nContext);

  const faqs = [
    [t('faqq1'), t('faqa1')],
    [t('faqq2'), t('faqa2')],
    [t('faqq3'), t('faqa3')],
    [t('faqq4'), t('faqa4')],
    [t('faqq5'), t('faqa5')],
    [t('faqq6'), t('faqa6')],
    [t('faqq7'), t('faqa7')],
    [t('faqq8'), t('faqa8')],
  ];
  const faqsApi = [
    [t('faqAPIq1'), t('faqAPIa1')],
    [t('faqAPIq2'), t('faqAPIa2')],
    [t('faqAPIq3'), t('faqAPIa3')],
    [t('faqAPIq4'), t('faqAPIa4')],
    [t('faqAPIq5'), t('faqAPIa5')],
    [t('faqAPIq6'), t('faqAPIa6')],
    [t('faqAPIq7'), t('faqAPIa7')],
    [t('faqAPIq8'), t('faqAPIa8')],
  ];

  const faqOrders = [
    [t('faqOq1'), t('faqOa1')],
    [t('faqOq2'), t('faqOa2')],
    [t('faqOq3'), t('faqOa3')],
    [t('faqOq4'), t('faqOa4')],
    [t('faqOq5'), t('faqOa5')],
    [t('faqOq6'), t('faqOa6')],
    [t('faqOq7'), t('faqOa7')],
  ];

  return (
    <div
      style={{
        marginTop: '3rem',
        marginLeft: windowSize > 800 ? '12rem' : '1rem',
        marginRight: windowSize > 800 ? '12rem' : '1rem',
        marginBottom: '3rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{}}>{t('faq')}</h1>
        <h2 style={{}}>{t('general')}</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}
        >
          {faqs.map((faq, index) => (
            <AccordionItem
              title={faq[0]}
              content={<p>{faq[1]}</p>}
              windowSize={windowSize}
            />
          ))}
        </div>
        <h2
          style={{
            marginTop: '2rem',
            marginBottom: '1rem',
          }}
        >
          {t('order')}
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}
        >
          {faqOrders.map((faq, index) => (
            <AccordionItem
              title={faq[0]}
              content={<p>{faq[1]}</p>}
              windowSize={windowSize}
            />
          ))}
        </div>
        <h2
          style={{
            marginTop: '2rem',
            marginBottom: '1rem',
          }}
        >
          {t('apiRelated')}
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}
        >
          {faqsApi.map((faq, index) => (
            <AccordionItem
              title={faq[0]}
              content={<p>{faq[1]}</p>}
              windowSize={windowSize}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
