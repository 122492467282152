import React from 'react';
import useWindowSize from '../../../Hooks/useWindowSize';

const PageHeader = ({ title }) => {
  const size = useWindowSize();
  return (
    <div
      style={{
        fontSize: size < 800 ? '3rem' : '6rem',
        width: '100%',
        textAlign: 'center',
        background: 'linear-gradient(to right, #D1D5DB, rgb(19, 141, 201))',
        marginBottom: '2rem',
        paddingTop: '4rem',
        paddingBottom: '4rem',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
      }}
    >
      {title}
    </div>
  );
};

export default PageHeader;
