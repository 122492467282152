import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './OtpVerified.css';
import axios from 'axios';

function OtpVerified() {
  const history = useHistory();
  const [verified, setVerified] = useState(false);

  const code = new URLSearchParams(document.location.search).get('code');

  const redirectOnClick = async () => {
    if (verified) {
      history.push('/login');
    }
  };
  useEffect(() => {
    setTimeout(() => {
      redirectOnClick();
    }, 3000);
  }, [verified]);

  useEffect(() => {
    (async () => {
      const boole = await requestVerification();
      if (boole) {
        setVerified(true);
      }
    })();
  }, []);

  const requestVerification = async () => {
    try {
      const response = await axios.get(`/users/verifyOtp?code=${code}`);
      return response.data;
    } catch (err) {
      return false;
    }
  };

  return (
    <div
      style={{
        marginTop: '10%',
        width: '100%',
      }}
    >
      <div
        className="container"
        style={{
          textAlign: 'center',
        }}
      >
        {!verified && (
          <>
            <div>Verifying your Email</div>
            <span className="loaderCus mt-2"></span>
          </>
        )}
        {verified && (
          <>
            <div>Verified Successfully</div>
            <div>Redirecting to Login Page</div>
            <span className="loaderCus mt-2"></span>
          </>
        )}
      </div>
    </div>
  );
}

export default OtpVerified;
