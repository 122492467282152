import AnimatedNumber from 'animated-number-react';
import Axios from 'axios';
import exportFromJSON from 'export-from-json';
import * as FileSaver from 'file-saver';
import $ from 'jquery';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { Component, Fragment } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { AiFillCamera } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Footer from '../Footer';
import Header from '../Header';

// i18n
import { I18nContext } from '../../context/i18nContext.js';

// If Sign is done, on video page
// Add to cart if alreay signed in

// Email:
// New Supplier, Supplier List Update, Password Change, Email OTP for User and Supplier, Email to Upload List if not sent for the last 48 hrs

// DNA Page

pdfMake.vfs = pdfFonts.pdfMake.vfs;
class ViewInward extends Component {
  // importing i18next context
  static contextType = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      inwardData: [],
      inwardDetailData1: [],
      UserID: localStorage.getItem('user'),
      ClientToken: localStorage.getItem('token'),
      inwID: '',
      Discount: '',
      pushOffer: [],
      Multiple: [],
      arr: [],
      pager: {},
      pageOfItems: [],
      nextpage: '',
      total: 0,
      Avg: 0,
      data2: [],
      data: [],
      count: this.props.location.count,
      filtered: this.props.location.filtered,
      total: this.props.location.total,
      avg: this.props.location.avg,
      total: this.props.location.totalPrice,
      avg: this.props.location.avg1,
      record: this.props.location.record,
      pageSize: 10,
      startIndex: 0,
      endIndex: 0,
      InwardID: [],
      selected: {},
      image: '',
      visible: true,
      viewdata: [],
      storedata: [],
      storedata1: [],
      offerdiscount: '',
      makeofferValue: [],
      makeOfferID: [],
      FullName: '',
      EmailID: '',
      ContactNo: '',
      Message: '',
      totalWeight: 0,
      avgDiscount: 0,
      selectedtotal: 0,
      selectedavg: 0,
      selectedtotalWeight: 0,
      selectedavgDiscount: 0,
      selectedDiscount: 0,
      selectedAvg1: 0,
      loader: true,
      InwardID: [],
      deviceId: '',
      FullNameNotification: '',
      detailedView: true,
      windowSize: window.innerWidth,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxCheck = this.handleCheckboxCheck.bind(this);
    this.visibleofferbutton = this.visibleofferbutton.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.csvDownloadAllFilteredRecords =
      this.csvDownloadAllFilteredRecords.bind(this);
    this.PlaceOrder = this.PlaceOrder.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  PlaceOrder(e) {
    Axios.post('/users/placeorder', {
      Certificate: this.state.Multiple,
      UserID: this.state.UserID,
      InwardID: this.state.InwardID,
    }).then((res) => {
      swal('Order Place Successfully', '', 'success');
    });
  }

  ToggleView(e) {
    this.setState({
      detailedView: !this.state.detailedView,
    });
  }

  AdaptInwardForCSV(inward) {
    const adapted = {};
    const obj = {
      'Stock#': 'Stock',
      Shp: 'Shape',
      'Ct.': 'Weight',
      'Col.': 'Color',
      'Cla.': 'Clarity',
      DNA: 'Video',
      Avl: 'Availability',
      'Cut.': 'CutGrade',
      'Pol.': 'Polish',
      'Sym.': 'Symmetry',
      'Flu.': 'FluorescenceIntensity',
      Rap: 'Rap',
      RapAmt: 'RapAmt',
      RapDis: 'RapnetDiscount',
      '$/ct': 'RapNetPrice',
      Total$: 'NetValue',
      'MM.': 'Measurements',
      'Lab.': 'Lab',
      'Cert#': 'Certificate',
      Shade: 'Shade',
      Milky: 'Milky',
      'SD Comment': 'MemberComment',
      'Treat.': 'Treatment',
      'Dep.': 'Depth',
      'Tab.': 'Table',
      'G.THN': 'GirdleThin',
      'G.THK': 'GirdleThick',
      Girdle: 'Girdle',
      'G.CON.': 'GirdleCondition',
      'C.Size': 'CuletSize',
      'C.Cond.': 'CuletCondition',
      CH: 'CrownHeight',
      CA: 'CrownAngle',
      PD: 'PavilionDepth',
      PA: 'PavilionAngle',
      'KTS.': 'Keytosymbols',
      LI: 'LaserInscription',
      'C.Com.': 'Certcomment',
      Location: 'Country',
      State: 'State',
      City: 'City',
      'S.Leng.': 'StarLength',
      'Center Inc.': 'CenterInclusion',
      'Black Inc.': 'BlackInclusion',
    };

    const keys = Object.keys(obj);
    keys.forEach((key) => {
      adapted[key] = inward[obj[key]];
    });

    adapted['Col.'] = adapted['Col.']
      ? adapted['Col.']
      : inward.FancyColorIntensity +
        ' ' +
        inward.FancyColorOvertone +
        ' ' +
        inward.FancyColor;

    adapted['$/ct'] = adapted['$/ct']
      ? adapted['$/ct']
      : this.state.partyMarkup[inward.PartyName] && inward.FancyColorPrice
      ? inward.FancyColorPrice + this.state?.partyMarkup[inward.PartyName]
      : '';

    adapted['Total$'] = adapted['Total$']
      ? adapted['Total$']
      : this.state.partyMarkup[inward.PartyName] && inward.FancyColorPrice
      ? (inward.FancyColorPrice + this.state?.partyMarkup[inward.PartyName]) *
        inward.Weight
      : '';

    adapted['DNA'] =
      'https://shreediamonds.in' + `/Video?Certificate=${inward.Certificate}`;

    return adapted;
  }

  csvDownloadAllFilteredRecords(e) {
    const data = this.state.record;
    var curDate = new Date();
    const fileName =
      curDate.getFullYear() +
      '_' +
      (curDate.getMonth() + 1) +
      '_' +
      curDate.getDate() +
      '_Inward';
    const exportType = 'csv';
    console.log('rahul', data);

    const adaptedData = data.map((inward) => this.AdaptInwardForCSV(inward));
    exportFromJSON({ data: adaptedData, fileName, exportType });
  }

  csvDownloadData(e, id) {
    e.preventDefault();
    Axios.get(`/users/pdfdemo/${this.state.Multiple}`).then((resp) => {
      console.log({ rahul: resp.data });
      this.setState({
        viewdata: resp.data,
      });
      const data = this.state.viewdata;
      var curDate = new Date();

      const fileName =
        curDate.getFullYear() +
        '_' +
        (curDate.getMonth() + 1) +
        '_' +
        curDate.getDate() +
        '_Inward';
      const exportType = 'csv';

      const adaptedData = resp.data.map((inward) =>
        this.AdaptInwardForCSV(inward)
      );

      exportFromJSON({ data: adaptedData, fileName, exportType });
    });
  }

  pdfDownload(e, id) {
    e.preventDefault();
    Axios.get(`/users/pdfdemo/${this.state.Multiple}`).then((res) => {
      var docDefinition;
      this.setState({
        viewdata: res.data,
      });

      for (var i = 0; i < this.state.viewdata.length; i++) {
        var data = [
          { text: 'View Inward', style: 'header' },
          { text: 'Stock :' + this.state.viewdata[i].Stock },
          { text: 'Availability :' + this.state.viewdata[i].Availability },
          { text: 'Shape :' + this.state.viewdata[i].Shape },
          { text: 'Weight :' + this.state.viewdata[i].Weight },
          { text: 'Color :' + this.state.viewdata[i].Color },
          { text: 'Clarity :' + this.state.viewdata[i].Clarity },
          { text: 'CutGrade : ' + this.state.viewdata[i].CutGrade },
          { text: 'Polish : ' + this.state.viewdata[i].Polish },
          { text: 'Symmentry :' + this.state.viewdata[i].Symmetry },
          {
            text:
              'FluorescenceIntensity : ' +
              this.state.viewdata[i].FluorescenceIntensity,
          },
          {
            text:
              'FluorescenceColor :' + this.state.viewdata[i].FluorescenceColor,
          },
          { text: 'Measurements : ' + this.state.viewdata[i].Measurements },
          { text: 'Lab :' + this.state.viewdata[i].Lab },
          { text: 'Certificate : ' + this.state.viewdata[i].Certificate },
          { text: 'Treatment :' + this.state.viewdata[i].Treatment },
          { text: 'Rap :' + this.state.viewdata[i].Rap },
          { text: 'RapAmt : ' + this.state.viewdata[i].RapAmt },
          { text: 'Discount :' + this.state.viewdata[i].RapnetDiscount },
          { text: 'NetPrice : ' + this.state.viewdata[i].RapNetPrice },
          { text: 'NetValue :' + this.state.viewdata[i].NetValue },
          { text: 'FancyColor : ' + this.state.viewdata[i].FancyColor },
          {
            text:
              'FancyColorIntensity :' +
              this.state.viewdata[i].FancyColorIntensity,
          },
          {
            text:
              'FancyColorOvertone : ' +
              this.state.viewdata[i].FancyColorOvertone,
          },
          { text: 'Depth :' + this.state.viewdata[i].Depth },
          { text: 'Table : ' + this.state.viewdata[i].Table },
          { text: 'GirdleThin :' + this.state.viewdata[i].GirdleThin },
          { text: 'GirdleThick :' + this.state.viewdata[i].GirdleThick },
          { text: 'Girdle :' + this.state.viewdata[i].Girdle },
          {
            text: 'GirdleCondition : ' + this.state.viewdata[i].GirdleCondition,
          },
          { text: 'CuletSize :' + this.state.viewdata[i].CuletSize },
          { text: 'CuletCondition : ' + this.state.viewdata[i].CuletCondition },
          { text: 'CrownHeight : ' + this.state.viewdata[i].CrownHeight },
          { text: 'CrownAngle :' + this.state.viewdata[i].CrownAngle },
          { text: 'PavilionDepth : ' + this.state.viewdata[i].PavilionDepth },
          { text: 'PavilionAngle : ' + this.state.viewdata[i].PavilionAngle },
          { text: 'Country :' + this.state.viewdata[i].Country },
          { text: 'DiamondImage : ' + this.state.viewdata[i].DiamondImage },

          { text: 'Keytosymbols :' + this.state.viewdata[i].Keytosymbols },
          { text: 'Shade : ' + this.state.viewdata[i].Shade },

          { text: 'Milky :' + this.state.viewdata[i].Milky },
        ];
        this.state.storedata.push(data);
      }
      docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',

        content: this.state.storedata,
        styles: {
          header: {
            fontSize: 22,
            bold: true,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download('ViewInward');
      this.setState({
        storedata: [],
      });
    });
  }

  CSVDownload(e, id) {
    Axios.get(`/users/downloadCSV/${id}`, { responseType: 'arraybuffer' }).then(
      (res) => {
        var blob = new Blob([res.data], { type: ' attachment/csv' });
        FileSaver.saveAs(blob, 'ViewInward.csv');
      }
    );
  }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.componentDidMount();
    });
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    window.onbeforeunload = function () {
      return 'Data will lost';
    };

    const filteredData = JSON.stringify(this.state.record);
    const params = new URLSearchParams(window.location.search);

    this.state.page = parseInt(params.get('page')) || 1;

    Axios.get('/users/ViewPartyDetails').then((res) => {
      // console.log({ res });
      const markUps = {};
      res.data.forEach((party) => {
        markUps[party.partyName] = party.FancyMarkup;
      });

      // console.log(markUps);
      this.setState({
        partyMarkup: markUps,
      });
    });

    if (this.state.page !== this.state.pager.currentPage) {
      Axios.get(
        `/users/InwardDetailData/${this.state.page}/${JSON.stringify(
          this.state.filtered
        )}/${this.state.count}`
      ).then((res) => {
        this.setState({
          pager: res.data.pager,
        });
      });
    }

    if (this.state.record != null) {
      this.calculation();
      var stringValue = this.state.record;
      this.setState({
        pageOfItems: stringValue.slice(
          this.state.pageSize * (this.state.page - 1),
          this.state.pageSize * this.state.page
        ),
        startIndex: this.state.pageSize * (this.state.page - 1),
        endIndex: this.state.page * 10,
      });
    } else {
      var stringValue = this.state.record;
      this.setState({
        pageOfItems: '',
        startIndex: 0,
        endIndex: 0,
      });
      // window.location.href = "http://localhost:3006/searchDiamond"
      window.location.href = '/searchDiamond';
    }

    Axios.get('/users/ViewPartyDetails').then((res) => {
      // prints all supplier
      // console.log("/users/ViewPartyDetails response is : ", res.data);
      const markUps = {};
      const newMarkUps = {};
      res.data.forEach((party) => {
        markUps[party.partyName] = party.FancyMarkup;

        if (party.IsActive) {
          newMarkUps[party?.partyName] = {
            FancyMarkup: party?.FancyMarkup,
            Markup: party?.Markup,
            WhiteMarkup: party?.WhiteMarkup,
            discount: party?.discount,
            // VendorDiscount: party?.VendorDiscount,
          };
        }
      });

      // the markUps object stores all parties along with their FancyMarkup value
      // console.log("Markups are :  ", markUps);
      this.setState({
        newMarkUpState: newMarkUps,
        partyMarkup: markUps,
        partyList: res.data,
      });
    });
  }

  calculation() {
    this.state.total = 0;
    this.state.totalWeight = 0;
    this.state.avg = 0;
    this.state.avgDiscount = 0;
    var rapnetDiscount = 0;
    var processData = this.state.record.map(
      (view, index) => (
        (this.state.total = this.state.total + view.Rap),
        (this.state.totalWeight = this.state.totalWeight + view.Weight),
        (rapnetDiscount = rapnetDiscount + view.RapnetDiscount)
      )
    );

    this.state.avg = this.state.total / this.state.record.length;
    this.state.avgDiscount = rapnetDiscount / this.state.record.length;
  }

  AddWishlist(e, id) {
    e.preventDefault();
    Axios.post(`/users/Wishlist`, this.state).then((res) => {
      if (res.data.msg === 'Error') {
        swal({
          title: 'Fail!',
          text: 'Inward Already in Wishlist',
          icon: 'warning',
          timer: 1000,
          button: false,
        });
      } else {
        swal({
          title: 'Done!',
          text: 'Inward Added in Wishlist',
          icon: 'success',
          timer: 1000,
          button: false,
        });
      }
    });
  }
  AddSingleWishlist(e, id) {
    e.preventDefault();
    Axios.post(`/users/Wishlist/${id}`, this.state).then((res) => {
      if (res.data.msg === 'Error') {
        swal({
          title: 'Fail!',
          text: 'Inward Already in Wishlist',
          icon: 'warning',
          timer: 2000,
          button: false,
        });
      } else {
        swal({
          title: 'Done!',
          text: 'Inward Added in Wishlist',
          icon: 'success',
          timer: 2000,
          button: false,
        });
      }
    });
  }
  Addcart(e, id) {
    e.preventDefault();
    Axios.post(`/users/Addcart`, this.state).then((res) => {
      if (res.data.msg === 'Error') {
        swal({
          title: 'Fail!',
          text: 'Inward Already in Cart',
          icon: 'warning',
          timer: 2000,
          button: false,
        });
      } else {
        swal({
          title: 'Done!',
          text: 'Inward Added in Cart',
          icon: 'success',
          timer: 2000,
          button: false,
        });
      }
    });
  }
  AddSinglecart(e, id) {
    e.preventDefault();
    Axios.post(`/users/Addcart/${id}`, this.state).then((res) => {
      if (res.data.msg === 'Error') {
        swal({
          title: 'Fail!',
          text: 'Inward Already in Cart',
          icon: 'warning',
          timer: 2000,
          button: false,
        });
      } else {
        swal({
          title: 'Done!',
          text: 'Inward Added in Cart',
          icon: 'success',
          timer: 2000,
          button: false,
        });
      }
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  visibleofferbutton(e) {
    e.preventDefault();
    this.setState({
      visible: false,
    });
    // $("tr:first").append("<th>Offer</th>");
    // $("tr:not(:first)").append("<td><input type='text' className='form-control dynamictextbox'/></td>")
    $('#offer').css('display', 'block');
    $('#offertd').css('display', 'block');
  }
  makeOffer(e, id) {
    Axios.post('/users/offerData', this.state).then((resp) => {
      if (resp.data.msg === 'success') {
        swal('Offer Sent', '', {
          icon: 'success',
          buttons: false,
          timer: 1500,
        });
        this.setState({
          visible: true,
        });
      }
    });
  }
  setDiscount(value, id, index) {
    this.state.makeofferValue.splice(index, 1, value);
    this.state.makeOfferID.splice(index, 1, id);
  }
  handleCheckboxCheck(e, view) {
    this.setState({
      InwardID: e.target.value,
    });

    $(':checkbox').change(function () {
      $(this)
        .closest('tr')
        .find('td')
        .toggleClass('checkedHighlight', this.checked);
    });
    view.isChecked = false;
    if (e.target.checked === true) {
      this.state.Multiple.push(view.Certificate);
      this.state.InwardID.push(view._id);
      view.isChecked = true;
      this.state.selectedtotal += view.NetValue;
      this.state.selectedAvg1 += view.RapNetPrice;
      this.state.selectedAvg =
        this.state.selectedAvg1 / this.state.Multiple.length;
      this.state.selectedtotalWeight += view.Weight;
      this.state.selectedDiscount += view.RapnetDiscount;
      this.state.selectedavgDiscount =
        this.state.selectedDiscount / this.state.Multiple.length;
    } else {
      // this.state.selectedtotal -= view.Rap
      // this.state.selectedavg = this.state.selectedtotal / this.state.Multiple.length
      // this.state.selectedtotalWeight -= view.Weight
      // this.state.selectedtotalWeight = this.state.selectedtotalWeight / this.state.Multiple.length
      var index = this.state.Multiple.indexOf(e.target.value);
      var index1 = this.state.InwardID.indexOf(e.target.value);
      this.state.Multiple.splice(index, 1);
      this.state.InwardID.splice(index, 1);
      this.state.selectedtotal -= view.NetValue;
      this.state.selectedAvg1 -= view.RapNetPrice;
      this.state.selectedAvg =
        this.state.selectedAvg1 / this.state.Multiple.length;
      this.state.selectedtotalWeight -= view.Weight;
      this.state.selectedDiscount -= view.RapnetDiscount;
      this.state.selectedavgDiscount =
        this.state.selectedDiscount / this.state.Multiple.length;
    }
    this.setState({
      Multiple: this.state.Multiple,
      InwardID: this.state.InwardID,
    });
  }
  Compare(e, id) {
    e.preventDefault();
    if (this.state.Multiple.length < 2) {
      swal('Please , select 2 or more stones', {
        buttons: false,
        timer: 1500,
        icon: 'warning',
      });
    } else {
      Axios.get(`/users/CompareStones/${this.state.Multiple}`).then((res) => {
        this.setState({
          arr: res.data.inward,
        });
      });
    }
  }
  buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };
  filterData = (data, query) => {
    const filteredData = data.filter((item) => {
      for (let key in query) {
        if (item[key] === undefined || !query[key].includes(item[key])) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };
  getData(e) {
    Axios.get(`/users/getUserInformation/${localStorage.getItem('user')}`).then(
      (res) => {
        this.setState({
          FullName: res.data.user.FullName,
          EmailID: res.data.user.EmailID,
          ContactNo: res.data.user.ContactNo,
        });
      }
    );
  }
  handleChangeMessage(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  inquiry(e) {
    Axios.post(
      `users/inquiry/${localStorage.getItem('user')}/${this.state.Multiple}`,
      this.state
    ).then((res) => {
      if (res.data.msg.includes('Success')) {
        swal('Message Sent', '', {
          buttons: false,
          icon: 'success',
          timer: 1500,
        });
        $('.close').trigger('click');
        this.setState({
          Message: '',
          ContactNo: '',
        });
      }
    });
  }

  formatpics = (value) => `${Number(value).toFixed(0)}`;
  formatValue = (value) => ` ${Number(value).toFixed(0)}`;
  formatValueAvg = (value) => ` ${Number(value).toFixed(2)}`;
  formatValueWeight = (value) => `${Number(value).toFixed(2)}`;
  formatValueAvgDiscount = (value) => `${Number(value).toFixed(2)}`;

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
  };

  // Handling change in language
  handleChangeLanguage(event) {
    const { changeLanguage } = this.context;
    changeLanguage(event.target.value);
  }

  render() {
    // destructing the required properties from i18 context
    const { t, changeLanguage } = this.context;

    const style = {
      background: 'blue',
    };

    const polishMappings = {
      EX: 'Excellent',
      VG: 'Very Good',
      G: 'Good',
    };

    const symmetryMappings = {
      EX: 'Excellent',
      VG: 'Very Good',
      G: 'Good',
    };

    const { pager, pageOfItems } = this.state;

    return (
      <div className="">
        <Header />
        {/* {this.state.loader ? <div className="loading"><img src="./dist/img/loading.gif" height="150" width="150"></img></div> : ''} */}
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content viewinward">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card main-card">
                      {/* Features at the top: Inquiry, make offer, downlod csv, etc all the filters */}
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-12 d-flex stone_info">
                            <div>
                              <label>
                                <b>ALL Pcs:</b>{' '}
                                <AnimatedNumber
                                  value={
                                    this.state.record
                                      ? this.state.record.length
                                      : 0
                                  }
                                  formatValue={this.formatpics}
                                />
                              </label>
                            </div>
                            <div>
                              <label>
                                <b>Amount:</b>{' '}
                                <AnimatedNumber
                                  value={parseInt(this.state.total)}
                                  formatValue={this.formatValue}
                                />
                              </label>
                            </div>

                            <div>
                              <label className="">
                                <b>Avg Price:</b>{' '}
                                <AnimatedNumber
                                  value={this.state.avg}
                                  formatValue={this.formatValueAvg}
                                />
                              </label>
                            </div>

                            <div>
                              <label className="">
                                <b>Cts:</b>{' '}
                                <AnimatedNumber
                                  value={this.state.totalWeight}
                                  formatValue={this.formatValueWeight}
                                />
                              </label>
                            </div>

                            <div>
                              <label className="">
                                <b>Avg Disc:</b>{' '}
                                <AnimatedNumber
                                  value={this.state.avgDiscount}
                                  formatValue={this.formatValueAvgDiscount}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* This will be rendered when any diamond is checked  */}
                        {this.state.Multiple.length !== 0 ? (
                          <div className="row">
                            <div className="col-md-12 d-flex stone_info">
                              <div style={{ display: 'inline-flex' }}>
                                <label>
                                  <b>Selected Pcs:</b>{' '}
                                  <AnimatedNumber
                                    value={this.state.Multiple.length}
                                    formatValue={this.formatpics}
                                  />
                                </label>
                              </div>
                              <div style={{ display: 'inline-flex' }}>
                                <label>
                                  <b>Amount:</b>{' '}
                                  <AnimatedNumber
                                    value={parseInt(this.state.selectedtotal)}
                                    formatValue={this.formatValue}
                                  />
                                </label>
                              </div>

                              <div style={{ display: 'inline-flex' }}>
                                <label className="">
                                  <b>Avg Price:</b>{' '}
                                  <AnimatedNumber
                                    value={this.state.selectedAvg}
                                    formatValue={this.formatValueAvg}
                                  />
                                </label>
                              </div>

                              <div style={{ display: 'inline-flex' }}>
                                <label className=" ">
                                  <b>Cts:</b>{' '}
                                  <AnimatedNumber
                                    value={this.state.selectedtotalWeight}
                                    formatValue={this.formatValueWeight}
                                  />
                                </label>
                              </div>

                              <div style={{ display: 'inline-flex' }}>
                                <label className=" ">
                                  <b>Avg Disc:</b>{' '}
                                  <AnimatedNumber
                                    value={this.state.selectedavgDiscount}
                                    formatValue={this.formatValueAvgDiscount}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {/* All the filter/other options starts here */}
                        {this.state.record ? (
                          <div className="row">
                            <div className="col-md-2 col-6 d-flex">
                              <button
                                id="inquiry"
                                className="btn btn-info w-100 m-1"
                                data-toggle="modal"
                                data-target=".bd-example-modal-lg1"
                                onClick={(e) => {
                                  this.getData(e);
                                }}
                                data-whatever="@mdo"
                                disabled={this.state.Multiple.length == 0}
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;{t('inquiry')}
                              </button>
                            </div>
                            <div
                              className="modal fade bd-example-modal-lg1"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {t('inquiry')}
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Name"
                                              id="Name"
                                              value={this.state.FullName}
                                              readOnly
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Email ID"
                                              id="EmailID"
                                              value={this.state.EmailID}
                                              readOnly
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Contact No"
                                              id="ContactNo"
                                              name="ContactNo"
                                              value={this.state.ContactNo}
                                              onChange={
                                                this.handleChangeMessage
                                              }
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Certificate"
                                              id="Certificate"
                                              value={this.state.Multiple}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <textarea
                                              className="form-control"
                                              id="message-text"
                                              name="Message"
                                              placeholder="Message"
                                              rows="8"
                                              onChange={
                                                this.handleChangeMessage
                                              }
                                              value={this.state.Message}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                      onClick={(e) => {
                                        this.inquiry(e);
                                      }}
                                    >
                                      {t('inquiry')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.windowSize > 480 && (
                              <>
                                <div className="col-md-2 col-6 d-flex">
                                  {this.state.visible === true ? (
                                    <button
                                      id="makeoffer"
                                      className="btn btn-info w-100 m-1"
                                      onClick={(e) => {
                                        this.visibleofferbutton(e);
                                        if (this.state.detailedView) {
                                          this.ToggleView();
                                        }
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>&nbsp;
                                      {t('makeAnOffer')}
                                    </button>
                                  ) : (
                                    <button
                                      id="makeoffer"
                                      className="btn btn-danger m-1 w-100"
                                      onClick={(e) => {
                                        this.makeOffer(e);
                                      }}
                                    >
                                      <i className="fa fa-plus"></i>&nbsp;Save
                                      an Offer
                                    </button>
                                  )}
                                </div>

                                <div className="col-md-2 col-6 d-flex">
                                  <Link
                                    to="/searchDiamond"
                                    className="btn btn-info w-100 m-1"
                                  >
                                    <i className="fas fa-filter"></i>
                                    &nbsp;{t('modifySearch')}
                                  </Link>
                                </div>

                                <div className="col-md-2 col-6 d-flex">
                                  <button
                                    className="btn btn-info w-100 m-1"
                                    onClick={(e) => {
                                      this.AddWishlist(e, this.state.Multiple);
                                    }}
                                    disabled={this.state.Multiple == 0}
                                  >
                                    <i className="fa fa-heart"></i>
                                  </button>
                                </div>

                                <div className="col-md-2 col-6 d-flex">
                                  <button
                                    className="btn btn-info w-100 m-1"
                                    onClick={(e) => {
                                      this.Addcart(e, this.state.Multiple);
                                    }}
                                    disabled={this.state.Multiple == 0}
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </button>
                                </div>

                                <div className="col-md-2 col-6 d-flex">
                                  <button
                                    className="btn btn-info w-100 m-1"
                                    onClick={(e) => this.PlaceOrder(e)}
                                  >
                                    {t('placeOrder')}
                                  </button>
                                </div>
                              </>
                            )}

                            <div className="col-md-2 col-6 d-flex">
                              <button
                                className="btn btn-info w-100 m-1"
                                onClick={(e) => {
                                  this.csvDownloadData(e, this.state.Multiple);
                                }}
                                disabled={this.state.Multiple.length == 0}
                              >
                                <i className="fa fa-download"></i>&nbsp;
                                {t('downloadCSV')}
                              </button>
                            </div>

                            <div className="col-md-2 col-6 d-flex">
                              <button
                                className="btn btn-info w-100 m-1"
                                onClick={(e) => {
                                  this.Compare(e, this.state.Multiple);
                                }}
                                disabled={this.state.Multiple.length < 2}
                              >
                                <i className="far fa-gem"></i>&nbsp;
                                {t('compareStone')}
                              </button>
                            </div>

                            <div className="col-md-2 col-6 d-flex">
                              <Link
                                to="/ViewCart"
                                className="btn btn-info w-100 m-1"
                              >
                                <i className="fa fa-shopping-cart" />
                                &nbsp;&nbsp;{t('viewCart')}
                              </Link>
                            </div>

                            <div className="col-md-2 col-6 d-flex">
                              <button
                                className="btn btn-info w-100 m-1"
                                onClick={(e) => {
                                  this.csvDownloadAllFilteredRecords(e);
                                }}
                              >
                                {t('downloadAll')}
                              </button>
                            </div>

                            <div className="col-md-2 col-6 d-flex">
                              <button
                                className="btn btn-info w-100 m-1"
                                onClick={(e) => {
                                  this.ToggleView(e);
                                }}
                              >
                                {this.state.detailedView
                                  ? t('tabularView')
                                  : t('detailedView')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <Link
                            to="/searchDiamond"
                            className="btn btn-info ml-1"
                          >
                            <i className="fas fa-filter"></i>&nbsp;
                            {t('modifySearch')}
                          </Link>
                        )}
                      </div>

                      {/* Part below the header starts i.e the above part  */}
                      <div className="card-body">
                        {this.state.record ? (
                          <Fragment>
                            {!this.state.detailedView && (
                              <div className="table-responsive zui-wrapper ">
                                <div className="zui-scroller ">
                                  <table
                                    id="example1"
                                    className="table table-bordered table-striped zui-table "
                                  >
                                    <thead>
                                      <tr className="text-center">
                                        <th
                                          className="zui-sticky-col1"
                                          style={{ height: '39px' }}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{
                                              display: 'none',
                                              height: '35px',
                                            }}
                                          />
                                        </th>
                                        <th className="zui-sticky-col">
                                          Stock#
                                        </th>
                                        <th className="zui-sticky-col7">DNA</th>
                                        <th className="zui-sticky-col8">Avl</th>
                                        <th className="zui-sticky-col2">Shp</th>
                                        <th className="zui-sticky-col3">Ct.</th>
                                        <th className="zui-sticky-col4">
                                          Col.
                                        </th>
                                        <th className="zui-sticky-col5">
                                          Cla.
                                        </th>
                                        {/* <th>Video</th> */}
                                        <th>Cut.</th>
                                        <th>Pol.</th>
                                        <th>Sym.</th>
                                        <th>FL.</th>
                                        <th>Rap</th>
                                        <th>RapAmt</th>
                                        <th id="rapnetdiscount">Disc.</th>
                                        {this.state.visible === false ? (
                                          <th id="offer">Offer</th>
                                        ) : (
                                          <th style={{ display: 'none' }}></th>
                                        )}
                                        <th>$/ct</th>
                                        <th>Total$</th>
                                        <th>MM.</th>
                                        <th>Lab.</th>
                                        <th>Cert#</th>
                                        <th>Shade</th>
                                        <th>Milky</th>
                                        <th>SD Comment</th>
                                        <th>Treat.</th>
                                        <th>Dep.</th>
                                        <th>Tab.</th>
                                        <th>G.THN</th>
                                        <th>G.THK</th>
                                        <th>Girdle</th>
                                        <th>G.CON.</th>
                                        <th>C.Size</th>
                                        <th>C.Cond.</th>
                                        <th>CH</th>
                                        <th>CA</th>
                                        <th>PD</th>
                                        <th>PA</th>
                                        <th>KTS.</th>
                                        <th>LI</th>
                                        <th>C.Com.</th>
                                        <th className="zui-sticky-col6">
                                          Location
                                        </th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>S.Leng.</th>
                                        <th>Center Inc.</th>
                                        <th>Black Inc.</th>

                                        {/* <th>PDF Export</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.pageOfItems.map(
                                        (view, index) => (
                                          <tr
                                            key={view._id}
                                            className="text-center"
                                          >
                                            <td
                                              key={view._id}
                                              className="zui-sticky-col1"
                                            >
                                              <input
                                                type="checkbox"
                                                id={view._id}
                                                checked={view.isChecked}
                                                value={view._id}
                                                onChange={(e) =>
                                                  this.handleCheckboxCheck(
                                                    e,
                                                    view
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="zui-sticky-col">
                                              {view.Stock}
                                            </td>

                                            <td className="zui-sticky-col2">
                                              {view.Shape}
                                            </td>
                                            <td className="zui-sticky-col3">
                                              {view.Weight}
                                            </td>
                                            {view.Color ? (
                                              <td className="zui-sticky-col4">
                                                {view.Color}
                                              </td>
                                            ) : (
                                              <td
                                                className="zui-sticky-col4"
                                                title={
                                                  view.FancyColorIntensity +
                                                  ' ' +
                                                  view.FancyColorOvertone +
                                                  ' ' +
                                                  view.FancyColor
                                                }
                                              >
                                                {view.FancyColorIntensity +
                                                  ' ' +
                                                  view.FancyColorOvertone +
                                                  ' ' +
                                                  view.FancyColor}
                                              </td>
                                            )}
                                            <td className="zui-sticky-col5">
                                              {view.Clarity}
                                            </td>
                                            {
                                              <td className="zui-sticky-col7">
                                                <Link
                                                  to={`/Video?Certificate=${view.Certificate}`}
                                                  target="_blank"
                                                >
                                                  <i className="fas fa-video"></i>
                                                </Link>
                                              </td>
                                            }
                                            <td className="zui-sticky-col8">
                                              {view.Availability
                                                ? view.Availability
                                                : '-'}
                                            </td>
                                            {/* <td><Link to={`/Video1?Certificate=${view.Certificate}`} target="_blank"><i className="fas fa-video"></i></Link></td> */}
                                            <td>{view.CutGrade}</td>
                                            <td>{view.Polish}</td>
                                            <td>{view.Symmetry}</td>
                                            <td>
                                              {view.FluorescenceIntensity}
                                            </td>
                                            <td>{view.Rap}</td>
                                            <td>{view.RapAmt}</td>
                                            <td id="rapnetdiscount">
                                              {view.RapnetDiscount}
                                            </td>
                                            {this.state.visible === false ? (
                                              <td>
                                                <DebounceInput
                                                  type="text"
                                                  id={view.Certificate}
                                                  name="offerdiscount"
                                                  className="form-control dynamictextbox"
                                                  onChange={(e) => {
                                                    this.setDiscount(
                                                      e.target.value,
                                                      view.Certificate,
                                                      index
                                                    );
                                                  }}
                                                  debounceTimeout={2000}
                                                />
                                              </td>
                                            ) : (
                                              <td
                                                style={{ display: 'none' }}
                                              ></td>
                                            )}
                                            <td>
                                              {/* {view.RapNetPrice}
                                              {!view.RapNetPrice &&
                                                this.state.partyMarkup &&
                                                this.state.partyMarkup[
                                                  view.PartyName
                                                ] &&
                                                view.FancyColorPrice &&
                                                (
                                                  view.FancyColorPrice +
                                                  this.state.partyMarkup[
                                                    view.PartyName
                                                  ]
                                                )?.toFixed(2)} */}

                                              {/* New pricing system */}
                                              {/* {this.state.newMarkUpState?.[
                                                view.PartyName
                                              ]?.discount === true
                                                ? Math.ceil(
                                                    view.Rap -
                                                      (Math.abs(
                                                        view?.VendorDiscount
                                                      ) /
                                                        100) *
                                                        view.Rap
                                                  )
                                                : view.Color === ''
                                                ? Math.ceil(
                                                    view?.Rap +
                                                      this.state
                                                        .newMarkUpState?.[
                                                        view.PartyName
                                                      ]?.FancyMarkup
                                                  )
                                                : Math.ceil(
                                                    view.Rap +
                                                      this.state
                                                        .newMarkUpState?.[
                                                        view.PartyName
                                                      ]?.WhiteMarkup
                                                  )} */}

                                                  {/** Update Pricing 5/10/24*/}
                                                  {view?.RapNetPrice.toFixed(0)}
                                            </td>
                                            <td>
                                              {/* {view.NetValue}
                                              {!view.NetValue &&
                                                this.state.partyMarkup &&
                                                this.state.partyMarkup[
                                                  view.PartyName
                                                ] &&
                                                view.FancyColorPrice &&
                                                (
                                                  (view.FancyColorPrice +
                                                    this.state?.partyMarkup[
                                                      view.PartyName
                                                    ]) *
                                                  view.Weight
                                                )?.toFixed(2)} */}

                                              {/* New pricing update */}
                                              {/* {this.state.newMarkUpState?.[
                                                view.PartyName
                                              ]?.discount === true
                                                ? Math.ceil(
                                                    (view.Rap -
                                                      (Math.abs(
                                                        view?.VendorDiscount
                                                      ) /
                                                        100) *
                                                        view.Rap) *
                                                      view.Weight
                                                  )
                                                : view.Color === ''
                                                ? Math.ceil(
                                                    (view?.Rap +
                                                      this.state
                                                        .newMarkUpState?.[
                                                        view.PartyName
                                                      ]?.FancyMarkup) *
                                                      view.Weight
                                                  )
                                                : Math.ceil(
                                                    (view.Rap +
                                                      this.state
                                                        .newMarkUpState?.[
                                                        view.PartyName
                                                      ]?.WhiteMarkup) *
                                                      view.Weight
                                                  )} */}

                                                  {/** Update pricing 5/10/24 */}
                                                  {view?.NetValue.toFixed(0)}
                                            </td>

                                            <td>{view.Measurements}</td>
                                            <td>{view.Lab}</td>
                                            <td>{view.Certificate}</td>
                                            <td>{view.Shade}</td>
                                            <td>{view.Milky}</td>
                                            <td>{view.MemberComment}</td>
                                            <td>{view.Treatment}</td>

                                            <td>{view.Depth}</td>
                                            <td>{view.Table}</td>
                                            <td>{view.GirdleThin}</td>
                                            <td>{view.GirdleThick}</td>
                                            <td>{view.Girdle}</td>
                                            <td>{view.GirdleCondition}</td>
                                            <td>{view.CuletSize}</td>
                                            <td>{view.CuletCondition}</td>
                                            <td>{view.CrownHeight}</td>
                                            <td>{view.CrownAngle}</td>
                                            <td>{view.PavilionDepth}</td>
                                            <td>{view.PavilionAngle}</td>
                                            <td>{view.Keytosymbols}</td>

                                            <td>{view.LaserInscription}</td>
                                            <td>{view.Certcomment}</td>
                                            <td className="zui-sticky-col6">
                                              {view.Country}
                                            </td>
                                            <td>{view.State}</td>
                                            <td>{view.City}</td>

                                            <td>{view.StarLength}</td>
                                            <td>{view.CenterInclusion}</td>
                                            <td>{view.BlackInclusion}</td>

                                            {/* <td><Link onClick={(e) => this.pdfDownload(e, view._id)}><i className="fa fa-download" style={{ color: "#00000080" }}></i></Link></td> */}
                                          </tr>
                                        )
                                      )}
                                      {
                                        /*  
                                    <th>$/ct</th>
                                    <th>Total$</th>
                                    <th>MM.</th>
                                    <th>Lab.</th>
                                    <th>Cert#</th>
                                    <th>Shade</th>
                                    <th>Milky</th>
                                    <th>SD Comment</th>
                                    <th>Treat.</th>
                                    <th>Dep.</th>
                                    <th>Tab.</th>
                                    <th>G.THN</th>
                                    <th>G.THK</th>
                                    <th>Girdle</th>
                                    <th>G.CON.</th>
                                    <th>C.Size</th>
                                    <th>C.Cond.</th>
                                    <th>CH</th>
                                    <th>CA</th>
                                    <th>PD</th>
                                    <th>PA</th>
                                    <th>KTS.</th>
                                    <th>LI</th>
                                    <th>C.Com.</th>
                                    <th className="zui-sticky-col6">
                                      Location
                                    </th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>S.Leng.</th>
                                    <th>Center Inc.</th>
                                    <th>Black Inc.</th>
                                    */

                                        /* 
                                    <td>{view.RapNetPrice}</td>
                                        <td>{view.NetValue}</td>

                                        <td>{view.Measurements}</td>
                                        <td>{view.Lab}</td>
                                        <td>{view.Certificate}</td>
                                        <td>{view.Shade}</td>
                                        <td>{view.Milky}</td>
                                        <td>{view.MemberComment}</td>
                                        <td>{view.Treatment}</td>

                                        <td>{view.Depth}</td>
                                        <td>{view.Table}</td>
                                        <td>{view.GirdleThin}</td>
                                        <td>{view.GirdleThick}</td>
                                        <td>{view.Girdle}</td>
                                        <td>{view.GirdleCondition}</td>
                                        <td>{view.CuletSize}</td>
                                        <td>{view.CuletCondition}</td>
                                        <td>{view.CrownHeight}</td>
                                        <td>{view.CrownAngle}</td>
                                        <td>{view.PavilionDepth}</td>
                                        <td>{view.PavilionAngle}</td>
                                        <td>{view.Keytosymbols}</td>

                                        <td>{view.LaserInscription}</td>
                                        <td>{view.Certcomment}</td>
                                        <td className="zui-sticky-col6">
                                          {view.Country}
                                        </td>
                                        <td>{view.State}</td>
                                        <td>{view.City}</td>

                                        <td>{view.StarLength}</td>
                                        <td>{view.CenterInclusion}</td>
                                        <td>{view.BlackInclusion}</td>

                                    */
                                        true &&
                                          (() => {
                                            const obj = {
                                              'Stock#': 'Stock',
                                              Shp: 'Shape',
                                              'Ct.': 'Weight',
                                              'Col.': 'Color',
                                              'Cla.': 'Clarity',
                                              DNA: 'Video',
                                              Avl: 'Availability',
                                              'Cut.': 'CutGrade',
                                              'Pol.': 'Polish',
                                              'Sym.': 'Symmetry',
                                              'Flu.': 'FluorescenceIntensity',
                                              Rap: 'Rap',
                                              RapAmt: 'RapAmt',
                                              RapDis: 'RapnetDiscount',
                                              '$/ct': 'RapNetPrice',
                                              Total$: 'NetValue',
                                              'MM.': 'Measurements',
                                              'Lab.': 'Lab',
                                              'Cert#': 'Certificate',
                                              Shade: 'Shade',
                                              Milky: 'Milky',
                                              'SD Comment': 'MemberComment',
                                              'Treat.': 'Treatment',
                                              'Dep.': 'Depth',
                                              'Tab.': 'Table',
                                              'G.THN': 'GirdleThin',
                                              'G.THK': 'GirdleThick',
                                              Girdle: 'Girdle',
                                              'G.CON.': 'GirdleCondition',
                                              'C.Size': 'CuletSize',
                                              'C.Cond.': 'CuletCondition',
                                              CH: 'CrownHeight',
                                              CA: 'CrownAngle',
                                              PD: 'PavilionDepth',
                                              PA: 'PavilionAngle',
                                              'KTS.': 'Keytosymbols',
                                              LI: 'LaserInscription',
                                              'C.Com.': 'Certcomment',
                                              Location: 'Country',
                                              State: 'State',
                                              City: 'City',
                                              'S.Leng.': 'StarLength',
                                              'Center Inc.': 'CenterInclusion',
                                              'Black Inc.': 'BlackInclusion',
                                            };
                                            return <></>;
                                          })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}

                            {/* Individual Diamond cards render starts here */}
                            {this.state.detailedView && (
                              <div>
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns:
                                      this.state.windowSize <= 440
                                        ? 'repeat(1, 1fr)'
                                        : this.state.windowSize < 800
                                        ? 'repeat(2, 1fr)'
                                        : 'repeat(3, 1fr)',
                                    gap: '1rem',
                                  }}
                                >
                                  {this.state.pageOfItems.map((view, index) => {
                                    return (
                                      <div
                                        key={view._id}
                                        style={{
                                          borderRadius: '0.5rem',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            height: '100%',
                                          }}
                                          className="card"
                                        >
                                          <div
                                            style={{
                                              flex: 1,
                                              padding: '0 1rem',
                                            }}
                                          >
                                            {view.DiamondImage ? (
                                              <div
                                                style={{
                                                  height: '10rem',
                                                  width: '100%',
                                                  background: `url(${view.DiamondImage})`,
                                                  backgroundSize: 'contain',
                                                  backgroundRepeat: 'no-repeat',
                                                  backgroundPosition: 'center',
                                                  marginLeft: '1rem',
                                                  marginRight: '1rem',
                                                  borderRadius: '0.5rem',
                                                }}
                                              />
                                            ) : (
                                              <div
                                                style={{
                                                  backgroundColor: '#f2f2f2',
                                                  position: 'relative',
                                                  marginTop: '1.5rem',
                                                  marginBottom: '1.5rem',
                                                  display: 'flex',
                                                  height: '10rem',
                                                  marginRight: 'auto',
                                                  marginLeft: 'auto',
                                                  maxWidth: '20rem',
                                                  borderRadius: '0.5rem',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  textAlign: 'center',
                                                  fontWeight: '600',
                                                  fontSize: '12px',
                                                }}
                                              >
                                                No Image Found
                                              </div>
                                            )}

                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1,
                                                marginBottom: '1.5rem',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  marginTop: '0.5rem',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: '0.2rem',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    position: 'relative',
                                                  }}
                                                  className="pl-4"
                                                >
                                                  <div
                                                    className="position-absolute"
                                                    style={{
                                                      right: 0,
                                                    }}
                                                  >
                                                    <Link
                                                      to={`/Video?Certificate=${view.Certificate}`}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      target="_blank"
                                                    >
                                                      <AiFillCamera
                                                        style={{
                                                          fontSize: '1.6rem',
                                                          cursor: 'pointer',
                                                          color: '#17a2b8',
                                                        }}
                                                      />
                                                    </Link>
                                                  </div>
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      top: '-2rem',
                                                    }}
                                                  >
                                                    {view.Lab}
                                                  </div>
                                                  <input
                                                    type="checkbox"
                                                    id={view._id}
                                                    checked={view.isChecked}
                                                    value={view._id}
                                                    onChange={(e) =>
                                                      this.handleCheckboxCheck(
                                                        e,
                                                        view
                                                      )
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                      position: 'absolute',
                                                      left: '0rem',
                                                      top: '0.2rem',
                                                    }}
                                                  />
                                                  <div
                                                    className=""
                                                    style={{
                                                      fontSize: '18px',
                                                    }}
                                                  >
                                                    {view.Stock}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: '18px',
                                                      marginTop: '-0.3rem',
                                                      fontWeight: '500',
                                                      color: 'black',
                                                    }}
                                                  >
                                                    {view.diamondimage}
                                                    {view.Shape} {view.Weight}{' '}
                                                    Carat {view.Color || view.FancyColor}-
                                                    {view.Clarity}{' '}
                                                    {view.CutGrade}{' '}
                                                    {view.Polish}
                                                    {/* {polishMappings[view.Polish]
                                                      ? `${
                                                          polishMappings[
                                                            view.Polish
                                                          ]
                                                        } Polish , `
                                                      : ''}{' '} */}{' '}
                                                    {/* {symmetryMappings[
                                                      view.Symmetry
                                                    ]
                                                      ? `${
                                                          symmetryMappings[
                                                            view.Symmetry
                                                          ]
                                                        } Symmetry`
                                                      : ''}{' '} */}
                                                    {view.Symmetry} -
                                                    {view.FluorescenceIntensity}{' '}
                                                  </div>
                                                </div>

                                                {/* <div
                                                  style={{
                                                    fontSize: '18px',
                                                    marginBottom: '2px',
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    gap: '0.2rem',
                                                  }}
                                                >
                                                  Stock Number :{' '}
                                                  <div
                                                    style={{
                                                      fontSize: '18px',
                                                      marginTop: '-0.3rem',
                                                      fontWeight: '700',
                                                    }}
                                                  >
                                                    {view.Stock}
                                                  </div>
                                                </div> */}
                                                <div
                                                  style={{
                                                    marginTop: '0.5rem',
                                                    fontWeight: '400',
                                                    fontSize: '1rem',
                                                    paddingLeft: '1.5rem',
                                                    borderTop: '1px solid',
                                                    borderColor: '#e2e2e2',
                                                    lineHeight: '1rem',
                                                    width: '100%',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontWeight: '600',
                                                    }}
                                                  >
                                                    Diamond Price
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        flexDirection: 'column',
                                                      }}
                                                    >
                                                      <div
                                                        className="pl-1"
                                                        style={{
                                                          color: '#138496',
                                                        }}
                                                      >
                                                        {/* ${view.RapNetPrice}
                                                        {!view.RapNetPrice &&
                                                          this.state
                                                            .partyMarkup &&
                                                          this.state
                                                            .partyMarkup[
                                                            view.PartyName
                                                          ] &&
                                                          (
                                                            view.FancyColorPrice &&
                                                            view.FancyColorPrice +
                                                              this.state
                                                                .partyMarkup[
                                                                view.PartyName
                                                              ]
                                                          )?.toFixed(2)} */}
                                                        {/* New update for pricing */}
                                                        $
                                                        {/* {this.state
                                                          .newMarkUpState?.[
                                                          view.PartyName
                                                        ]?.discount === true
                                                          ? Math.ceil(
                                                              view.Rap -
                                                                (Math.abs(
                                                                  view?.VendorDiscount
                                                                ) /
                                                                  100) *
                                                                  view.Rap
                                                            )
                                                          : view.Color === ''
                                                          ? Math.ceil(
                                                              view?.Rap +
                                                                this.state
                                                                  .newMarkUpState?.[
                                                                  view.PartyName
                                                                ]?.FancyMarkup
                                                            )
                                                          : Math.ceil(
                                                              view.Rap +
                                                                this.state
                                                                  .newMarkUpState?.[
                                                                  view.PartyName
                                                                ]?.WhiteMarkup
                                                            )} */}

                                                            {view?.RapNetPrice.toFixed(0)}
                                                        /ct
                                                      </div>
                                                      <div className="pl-1">
                                                        {/* $
                                                        {view?.NetValue?.toFixed(
                                                          2
                                                        )}
                                                        {!view.NetValue &&
                                                          this.state
                                                            .partyMarkup &&
                                                          this.state
                                                            .partyMarkup[
                                                            view.PartyName
                                                          ] &&
                                                          view.FancyColorPrice &&
                                                          (view.FancyColorPrice +
                                                            this.state
                                                              ?.partyMarkup[
                                                              view.PartyName
                                                            ]) *
                                                            view.Weight} */}
                                                        {/* New pricing update */}
                                                        ${view?.NetValue.toFixed(0)}
                                                        {/* {this.state
                                                          .newMarkUpState?.[
                                                          view.PartyName
                                                        ]?.discount === true
                                                          ? Math.ceil(
                                                              (view.Rap -
                                                                (Math.abs(
                                                                  view?.VendorDiscount
                                                                ) /
                                                                  100) *
                                                                  view.Rap) *
                                                                view.Weight
                                                            )
                                                          : view.Color === ''
                                                          ? Math.ceil(
                                                              (view?.Rap +
                                                                this.state
                                                                  .newMarkUpState?.[
                                                                  view.PartyName
                                                                ]
                                                                  ?.FancyMarkup) *
                                                                view.Weight
                                                            )
                                                          : Math.ceil(
                                                              (view.Rap +
                                                                this.state
                                                                  .newMarkUpState?.[
                                                                  view.PartyName
                                                                ]
                                                                  ?.WhiteMarkup) *
                                                                view.Weight
                                                            )} */}
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        flexDirection: 'column',
                                                        marginLeft: '30px',
                                                      }}
                                                    >
                                                      {/* <div>
                                                        Rap - {view.Rap}
                                                      </div> */}
                                                      <div>
                                                        Disc{' '}
                                                        {view.RapnetDiscount}%
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            {/* Individual Diamond cards render ends here */}
                          </Fragment>
                        ) : (
                          <div className="text-center">No Data Found</div>
                        )}
                      </div>
                      {/* Part below the header ends */}

                      {/* Pagination component starts here */}
                      {this.state.record ? (
                        <div className="showingtotal pl-3 pr-3">
                          {'Showing  ' +
                            parseInt(this.state.startIndex + 1) +
                            '  to ' +
                            parseInt(pager.endIndex + 1) +
                            '  of ' +
                            this.state.count +
                            '  entries'}

                          {pager.pages && pager.pages.length && (
                            <ul
                              className="pagination"
                              style={{ float: 'right', marginTop: '5px' }}
                            >
                              <li
                                className={`page-item previous-item ${
                                  this.state.page == 1 ? 'disabled' : ''
                                }`}
                              >
                                <Link
                                  to={{ search: `?page=${1}` }}
                                  className="page-link"
                                >
                                  First
                                </Link>
                              </li>
                              <li
                                className={`page-item previous-item ${
                                  this.state.page == 1 ? 'disabled' : ''
                                }`}
                              >
                                <Link
                                  to={{
                                    search: `?page=${this.state.page - 1}`,
                                  }}
                                  className="page-link"
                                >
                                  Previous
                                </Link>
                              </li>

                              {pager.pages.map((page) => (
                                <li
                                  key={page}
                                  className={`page-item number-item ${
                                    this.state.page === page ? 'active' : ''
                                  }`}
                                >
                                  <Link
                                    to={{ search: `?page=${page}` }}
                                    className="page-link"
                                  >
                                    {page}
                                  </Link>
                                </li>
                              ))}

                              <li
                                className={`page-item next-item ${
                                  pager.currentPage === pager.totalPages
                                    ? 'disabled'
                                    : ''
                                }`}
                              >
                                <Link
                                  to={{
                                    search: `?page=${
                                      parseInt(pager.currentPage) + 1
                                    }`,
                                  }}
                                  className="page-link"
                                >
                                  Next
                                </Link>
                              </li>
                              <li
                                className={`page-item last-item ${
                                  pager.currentPage === pager.totalPages
                                    ? 'disabled'
                                    : ''
                                }`}
                              >
                                <Link
                                  to={{ search: `?page=${pager.totalPages}` }}
                                  className="page-link"
                                >
                                  Last
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Pagination component ends here */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* Compare stone component start  */}
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Compare Stone
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="table-responsive" style={{ height: '300px' }}>
                  <div className="table-compare">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <th>Stock</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Stock}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Availability</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Availability}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Shape</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Shape}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Weight</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Weight}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Color</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Color}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Clarity</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Clarity}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CutGrade</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CutGrade}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Polish</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Polish}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Symmetry</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Symmetry}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>FluorescenceIntensity</th>
                          {this.state.arr.map((view) => (
                            <td>{view.FluorescenceIntensity}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>FluorescenceColor</th>
                          {this.state.arr.map((view) => (
                            <td>{view.FluorescenceColor}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Measurements</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Measurements}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Lab</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Lab}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Certificate</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Certificate}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Treatment</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Treatment}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Rap</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Rap}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>RapAmt</th>
                          {this.state.arr.map((view) => (
                            <td>{view.RapAmt}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>RapnetDiscount</th>
                          {this.state.arr.map((view) => (
                            <td>{view.RapnetDiscount}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>RapNetPrice</th>
                          {this.state.arr.map((view) => (
                            <td>{view.RapNetPrice}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>NetValue</th>
                          {this.state.arr.map((view) => (
                            <td>{view.NetValue}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CashPrice</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CashPrice}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CashPriceDiscount</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CashPriceDiscount}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>FancyColor</th>
                          {this.state.arr.map((view) => (
                            <td>{view.FancyColor}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>FancyColorIntensity</th>
                          {this.state.arr.map((view) => (
                            <td>{view.FancyColorIntensity}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>FancyColorOvertone</th>
                          {this.state.arr.map((view) => (
                            <td>{view.FancyColorOvertone}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Depth</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Depth}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Table</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Table}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>GirdleThin</th>
                          {this.state.arr.map((view) => (
                            <td>{view.GirdleThin}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>GirdleThick</th>
                          {this.state.arr.map((view) => (
                            <td>{view.GirdleThick}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Girdle</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Girdle}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>GirdleCondition</th>
                          {this.state.arr.map((view) => (
                            <td>{view.GirdleCondition}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CuletSize</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CuletSize}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CuletCondition</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CuletCondition}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CrownHeight</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CrownHeight}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CrownAngle</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CrownAngle}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>PavilionDepth</th>
                          {this.state.arr.map((view) => (
                            <td>{view.PavilionDepth}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>PavilionAngle</th>
                          {this.state.arr.map((view) => (
                            <td>{view.PavilionAngle}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>LaserInscription</th>
                          {this.state.arr.map((view) => (
                            <td>{view.LaserInscription}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Certcomment</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Certcomment}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Country</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Country}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>State</th>
                          {this.state.arr.map((view) => (
                            <td>{view.State}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>City</th>
                          {this.state.arr.map((view) => (
                            <td>{view.City}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Timetolocation</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Timetolocation}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>IsMatchedPairSeparable</th>
                          {this.state.arr.map((view) => (
                            <td>{view.IsMatchedPairSeparable}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>PairStock</th>
                          {this.state.arr.map((view) => (
                            <td>{view.PairStock}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>AllowRapLinkFeed</th>
                          {this.state.arr.map((view) => (
                            <td>{view.AllowRapLinkFeed}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>ParcelStones</th>
                          {this.state.arr.map((view) => (
                            <td>{view.ParcelStones}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CertificateFilename</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CertificateFilename}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>SarinLoupe</th>
                          {this.state.arr.map((view) => (
                            <td>{view.SarinLoupe}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>TradeShow</th>
                          {this.state.arr.map((view) => (
                            <td>{view.TradeShow}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Keytosymbols</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Keytosymbols}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Shade</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Shade}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>StarLength</th>
                          {this.state.arr.map((view) => (
                            <td>{view.StarLength}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>CenterInclusion</th>
                          {this.state.arr.map((view) => (
                            <td>{view.CenterInclusion}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>BlackInclusion</th>
                          {this.state.arr.map((view) => (
                            <td>{view.BlackInclusion}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>MemberComment</th>
                          {this.state.arr.map((view) => (
                            <td>{view.MemberComment}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>ReportIssueDate</th>
                          {this.state.arr.map((view) => (
                            <td>{view.ReportIssueDate}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>ReportType</th>
                          {this.state.arr.map((view) => (
                            <td>{view.ReportType}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>LabLocation</th>
                          {this.state.arr.map((view) => (
                            <td>{view.LabLocation}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Brand</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Brand}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Milky</th>
                          {this.state.arr.map((view) => (
                            <td>{view.Milky}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* Compare stone component end */}

        <Footer />
      </div>
    );
  }
}

export default ViewInward;
