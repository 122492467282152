import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Header from '../Header';

class ViewWishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewwishlist: [],
      UserID: localStorage.getItem('user'),
      Certificate: [],
    };
    this.handlecheckbox = this.handlecheckbox.bind(this);
    this.DeleteWishlist = this.DeleteWishlist.bind(this);
  }
  componentDidMount() {
    Axios.get(`/users/ViewWishlist/${this.state.UserID}`).then((res) => {
      this.setState({
        viewwishlist: res.data.inward,
      });
      const script = document.createElement('script');
      script.src = `js/content.js`;
      script.async = true;
      document.body.appendChild(script);
    });
  }
  DeleteWishlist() {
    Axios.post(`/users/DeleteWishlist/${localStorage.getItem('ID')}`, {
      Certificates: this.state.Certificate,
    }).then((resp) => {
      if (resp.data.msg === 'success') {
        window.location.reload();
      }
    });
  }
  handlecheckbox(e, cart) {
    console.log(this.state.Certificate);
    if (e.target.checked) {
      console.log({
        cart,
        rahul: cart.Certificate,
      });
      this.state.Certificate.push(cart.Certificate);
    } else {
      var certificate = this.state.Certificate.indexOf(cart.Certificate);
      this.state.Certificate.splice(certificate, 1);
    }
    this.setState({
      Certificate: this.state.Certificate,
    });
  }
  render() {
    const { viewwishlist } = this.state;
    return (
      <div className="top">
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card" style={{ marginTop: '2%' }}>
                      <div className="card-header">
                        <h3 className="card-title">View Wishlist</h3>
                      </div>

                      <div className="card-body">
                        <button
                          className="btn btn-info"
                          onClick={this.DeleteWishlist}
                          disabled={this.state.Certificate.length == 0}
                        >
                          Delete
                        </button>
                        <div className="table-responsive">
                          {viewwishlist.length == 0 ? (
                            <div>No data Available in Wishlist</div>
                          ) : (
                            <table
                              id="example1"
                              className="table table-bordered  zui-table"
                            >
                              <thead>
                                <tr className="text-center">
                                  <th></th>
                                  <th className="">Stock#</th>

                                  <th className="">Avl</th>
                                  <th className="">Shp</th>
                                  <th className="">Ct.</th>
                                  <th className="">Col.</th>
                                  <th className="">Cla.</th>

                                  <th>Cut.</th>
                                  <th>Pol.</th>
                                  <th>Sym.</th>
                                  <th>FL.</th>
                                  <th>Rap</th>
                                  <th>RapAmt</th>
                                  <th id="rapnetdiscount">Disc.</th>

                                  <th>$/ct</th>
                                  <th>Total$</th>
                                  <th>MM.</th>
                                  <th>Lab.</th>
                                  <th>Cert#</th>
                                  <th>Shade</th>
                                  <th>Milky</th>
                                  <th>SD Comment</th>
                                  <th>Treat.</th>
                                  <th>Dep.</th>
                                  <th>Tab.</th>
                                  <th>G.THN</th>
                                  <th>G.THK</th>
                                  <th>Girdle</th>
                                  <th>G.CON.</th>
                                  <th>C.Size</th>
                                  <th>C.Cond.</th>
                                  <th>CH</th>
                                  <th>CA</th>
                                  <th>PD</th>
                                  <th>PA</th>
                                  <th>KTS.</th>
                                  <th>LI</th>
                                  <th>C.Com.</th>
                                  <th className="">Location</th>
                                  <th>State</th>
                                  <th>City</th>
                                  <th>S.Leng.</th>
                                  <th>Center Inc.</th>
                                  <th>Black Inc.</th>

                                  {/* <th>PDF Export</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.viewwishlist.map((view, index) => (
                                  <tr key={view._id} className="text-center">
                                    <td>
                                      <input
                                        type="checkbox"
                                        id={view.InwardDetail.Certificate}
                                        onChange={(e) => {
                                          this.handlecheckbox(
                                            e,
                                            view.InwardDetail
                                          );
                                        }}
                                      />
                                    </td>
                                    <td className="">
                                      {view.InwardDetail.Stock}
                                    </td>
                                    <td className="">
                                      {view.InwardDetail.Availability
                                        ? view.InwardDetail.Availability
                                        : '-'}
                                    </td>
                                    <td className="">
                                      {view.InwardDetail.Shape}
                                    </td>
                                    <td className="">
                                      {view.InwardDetail.Weight}
                                    </td>
                                    {view.InwardDetail.Color ? (
                                      <td className="">
                                        {view.InwardDetail.Color}
                                      </td>
                                    ) : (
                                      <td
                                        className="zui-sticky-col4"
                                        title={
                                          view.InwardDetail
                                            .FancyColorIntensity +
                                          ' ' +
                                          view.InwardDetail.FancyColorOvertone +
                                          ' ' +
                                          view.InwardDetail.FancyColor
                                        }
                                      >
                                        {view.InwardDetail.FancyColorIntensity +
                                          ' ' +
                                          view.InwardDetail.FancyColorOvertone +
                                          ' ' +
                                          view.InwardDetail.FancyColor}
                                      </td>
                                    )}
                                    <td className="">
                                      {view.InwardDetail.Clarity}
                                    </td>
                                    <td>{view.InwardDetail.CutGrade}</td>
                                    <td>{view.InwardDetail.Polish}</td>
                                    <td>{view.InwardDetail.Symmetry}</td>
                                    <td>
                                      {view.InwardDetail.FluorescenceIntensity}
                                    </td>
                                    <td>{view.InwardDetail.Rap}</td>
                                    <td>{view.InwardDetail.RapAmt}</td>
                                    <td id="rapnetdiscount">
                                      {view.InwardDetail.RapnetDiscount}
                                    </td>
                                    <td>{view.InwardDetail.RapNetPrice}</td>
                                    <td>{view.InwardDetail.NetValue}</td>
                                    <td>{view.InwardDetail.Measurements}</td>
                                    <td>{view.InwardDetail.Lab}</td>
                                    <td>{view.InwardDetail.Certificate}</td>
                                    <td>{view.InwardDetail.Shade}</td>
                                    <td>{view.InwardDetail.Milky}</td>
                                    <td>{view.InwardDetail.MemberComment}</td>
                                    <td>{view.InwardDetail.Treatment}</td>

                                    <td>{view.InwardDetail.Depth}</td>
                                    <td>{view.InwardDetail.Table}</td>
                                    <td>{view.InwardDetail.GirdleThin}</td>
                                    <td>{view.InwardDetail.GirdleThick}</td>
                                    <td>{view.InwardDetail.Girdle}</td>
                                    <td>{view.InwardDetail.GirdleCondition}</td>
                                    <td>{view.InwardDetail.CuletSize}</td>
                                    <td>{view.InwardDetail.CuletCondition}</td>
                                    <td>{view.InwardDetail.CrownHeight}</td>
                                    <td>{view.InwardDetail.CrownAngle}</td>
                                    <td>{view.InwardDetail.PavilionDepth}</td>
                                    <td>{view.InwardDetail.PavilionAngle}</td>
                                    <td>{view.InwardDetail.Keytosymbols}</td>

                                    <td>
                                      {view.InwardDetail.LaserInscription}
                                    </td>
                                    <td>{view.InwardDetail.Certcomment}</td>
                                    <td className="">
                                      {view.InwardDetail.Country}
                                    </td>
                                    <td>{view.InwardDetail.State}</td>
                                    <td>{view.InwardDetail.City}</td>

                                    <td>{view.InwardDetail.StarLength}</td>
                                    <td>{view.InwardDetail.CenterInclusion}</td>
                                    <td>{view.InwardDetail.BlackInclusion}</td>

                                    {/* <td><Link onClick={(e) => this.pdfDownload(e, view._id)}><i className="fa fa-download" style={{ color: "#00000080" }}></i></Link></td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewWishlist;
