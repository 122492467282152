import React, { Component } from 'react';
import Axios from 'axios';
import swal from 'sweetalert';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { token } from 'morgan';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { messaging } from '../init-fcm';
import { I18nContext } from '../context/i18nContext';
class Login extends Component {
  static contextType = I18nContext;
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Password: '',
      deviceID: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    Axios.post('/login', this.state).then((res) => {
      // console.log("Request Data: ", this.state);

      // console.log("Response after login: ", res)
      if (res.data.errors != null) {
        swal('Invalid Username and password', '', 'error');
      } else {
        if (res.data.status === true && res.data.ActiveStatus === true) {
          // return <Redirect to="/customerHome"/>

          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', this.state.UserName);
          localStorage.setItem('ID', res.data.userID);

          this.props.history.push('/custHome');
        } else {
          swal('Invalid Username and Password', '', 'error');
        }
      }
    });
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async componentDidMount() {
    var token1;
    disableBrowserBackButton();
    // messaging.requestPermission()
    //     .then(async function () {
    //         const token = await messaging.getToken();

    //         localStorage.setItem('tokendevice', token)
    //     })
    //     .catch(function (err) {

    //     });

    console.log(window.location);
  }

  render() {
    const { t } = this.context;

    return (
      <div>
        <div className="col-1">
          <button
            className="btn  btn-dark  mt-1"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => this.props.history.push('/')}
          >
            &lt; {t('home')}
          </button>
        </div>
        <div className="wrapper">
          <center>
            <div
              className="login-box"
              style={{
                marginTop: '5%',
                transform: 'scale(1.01)',
                boxShadow:
                  '0 10px 20px rgb(0 0 0 / 54%), 0 4px 8px rgb(0 0 0 / 6%)',
                borderRadius: '20px',
              }}
            >
              <div className="login-logo pt-4">
                <i className="far fa-gem"></i>&nbsp;<b>Shree</b>Diamond
              </div>

              <div className="card">
                <div className="card-body login-card-body">
                  <p className="login-box-msg">{t('signInT')}</p>

                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${t('username')}`}
                        name="UserName"
                        value={this.state.UserName}
                        onChange={this.handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope"></span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder={`${t('password')}`}
                        name="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="icheck-primary"
                          style={{ float: 'left' }}
                        >
                          <input type="checkbox" id="remember" />
                          <label for="remember">
                            &nbsp;<span>{`${t('remember')}`}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-danger btn-block"
                        >
                          {`${t('signin')}`}
                        </button>
                      </div>
                    </div>
                  </form>

                  <p className="mb-1 pt-4">
                    <Link to="/forgotpassword">{`${t('forgotPass')}`}</Link>
                  </p>
                  <p className="mb-0">
                    <Link to="/addCustomer" className="text-center">
                    {`${t('newMember')}?`}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    );
  }
}

export default Login;
